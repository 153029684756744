import { useState, useRef, useEffect, createRef } from "react";
import {
  Card,
  Col,
  Collapse,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
} from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import AccountSettings from "./AccountSettings";
import OrganizationSettings from "./OrganizationSettings";
import SiteSettings from "./SiteSettings";

import useAuth from "../../hooks/useAuth";

const Settings = () => {
  const { user } = useAuth();
  const [activeTab, setActiveTab] = useState(0);
  const formikRefs = useRef([createRef(), createRef()]);

  // Reset form when activeTab changes
  useEffect(() => {
    formikRefs.current.forEach((ref, index) => {
      if (index === activeTab && ref.current && ref.current.resetForm) {
        ref.current.resetForm();
      }
    });
  }, [activeTab]);

  return (
    <>
      <Helmet title="Settings" />
      <Container fluid className="p-0">
        <h1 className="mb-3">Settings</h1>
        <Row>
          <Col sm="auto">
            <ListGroup className="mb-3">
              <ListGroupItem
                action
                active={activeTab === 0}
                onClick={() => {
                  setActiveTab(0);
                }}
              >
                Account Settings
              </ListGroupItem>
              {user?.isAdmin && (
                <ListGroupItem
                  action
                  active={activeTab === 1}
                  onClick={() => {
                    setActiveTab(1);
                  }}
                >
                  Organization Settings
                </ListGroupItem>
              )}
              <ListGroupItem
                action
                active={activeTab === 2}
                onClick={() => {
                  setActiveTab(2);
                }}
              >
                Site Settings
              </ListGroupItem>
            </ListGroup>
          </Col>
          <Col xs xl={8}>
            <Card className="border">
              <Card.Body>
                <Collapse in={activeTab === 0}>
                  <div>
                    <AccountSettings formikRef={formikRefs.current[0]} />
                  </div>
                </Collapse>
                <Collapse in={activeTab === 1}>
                  <div>
                    <OrganizationSettings formikRef={formikRefs.current[1]} />
                  </div>
                </Collapse>
                <Collapse in={activeTab === 2}>
                  <div>
                    <SiteSettings />
                  </div>
                </Collapse>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Settings;
