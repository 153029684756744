import axios from "axios";
import { handleError, buildQueryString } from "../utils/helper";

const HOST_URL = process.env.REACT_APP_HOST_URL;

const getReportReasons = async () => {
  try {
    return (await axios.get(`${HOST_URL}/reports/reasons`)).data;
  } catch (error) {
    return handleError(error);
  }
};

const getReports = async ({ reportId, lpn, claimId } = {}) => {
  const queryString = buildQueryString({ lpn, claimId });

  try {
    if (reportId) {
      return (await axios.get(`${HOST_URL}/reports/${reportId}`)).data;
    } else {
      return (await axios.get(`${HOST_URL}/reports${queryString}`)).data;
    }
  } catch (error) {
    return handleError(error);
  }
};

const getReportImages = async (reportId) => {
  try {
    return (await axios.get(`${HOST_URL}/reports/images/${reportId}`)).data;
  } catch (error) {
    return handleError(error);
  }
};

const updateReport = async (reportId, data) => {
  try {
    return (await axios.patch(`${HOST_URL}/reports/${reportId}`, data)).data;
  } catch (error) {
    return handleError(error);
  }
};

const services = {
  getReportReasons,
  getReports,
  getReportImages,
  updateReport,
};

export default services;
