import { Formik } from "formik";
import React, { useContext, useRef } from "react";
import { Button, Collapse, Form, Spinner, Stack } from "react-bootstrap";
import toast from "react-hot-toast";
import * as Yup from "yup";

import organizationsService from "../../services/organizations.service";

import { OrganizationsContext } from "./Organizations";

const OrganizationForm = ({ isEdit, setStatus }) => {
  const {
    submitting,
    setOrganizations,
    selectedOrganization,
    setSelectedOrganization,
    setShowOrganizationModal,
  } = useContext(OrganizationsContext);

  const fileInputRef = useRef(null); // Ref for the file input

  return (
    <Formik
      enableReinitialize
      initialValues={{
        logo: "",
        removeCurrentLogo: false,
        name: selectedOrganization?.name || "",
        phoneNumber: selectedOrganization?.phoneNumber || "",
      }}
      validationSchema={Yup.object().shape({
        logo: Yup.mixed().test(
          "fileFormat",
          "File must be of type PNG or JPEG.",
          (value) => {
            if (value) {
              return value.type === "image/png" || value.type === "image/jpeg";
            }
            return true; // No file selected, so validation passes
          }
        ),
        name: Yup.string()
          .max(50, "Name must be less than 50 characters long.")
          .required("Name is required."),
        phoneNumber: Yup.string()
          .max(50, "Phone Number must be less than 50 characters long.")
          .required("Phone Number is required."),
      })}
      onSubmit={async (values) => {
        setStatus();

        if (isEdit) {
          const result = await organizationsService.updateOrganization(
            selectedOrganization.organizationId,
            values
          );

          if (result?.success) {
            toast.success("Organization has successfully been updated!");
            setStatus({
              success: true,
              message: "Organization has successfully been updated!",
            });

            setOrganizations((prevState) => {
              return prevState
                .map((stateOrg) => {
                  if (
                    stateOrg.organizationId ===
                    result.updatedOrganization.organizationId
                  ) {
                    return result.updatedOrganization;
                  }
                  return stateOrg;
                })
                .sort((a, b) => {
                  if (a.isActive && b.isActive) {
                    return 0;
                  }
                  if (a.isActive) {
                    return -1;
                  }
                  if (b.isActive) {
                    return 1;
                  }
                  return a.isActive - b.isActive;
                });
            });

            setSelectedOrganization(result.updatedOrganization);
            setShowOrganizationModal(false);
          } else {
            toast.error("Something went wrong. Please try again.");
            setStatus({
              success: false,
              message: result.message,
            });
          }
        } else {
          delete values.removeCurrentLogo;

          const result = await organizationsService.createOrganization(values);

          if (result?.success) {
            toast.success("Organization has successfully been created!");
            setStatus({
              success: true,
              message: "Organization has successfully been created!",
            });

            setOrganizations((prevState) => {
              console.log(prevState);
              return prevState.concat(result.newOrganization).sort((a, b) => {
                if (a.isActive && b.isActive) {
                  return 0;
                }
                if (a.isActive) {
                  return -1;
                }
                if (b.isActive) {
                  return 1;
                }
                return a.isActive - b.isActive;
              });
            });

            setShowOrganizationModal(false);
          } else {
            toast.error("Something went wrong. Please try again.");
            setStatus({
              success: false,
              message: result.message,
            });
          }
        }
      }}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset,
        setFieldValue,
        touched,
        dirty,
        isSubmitting,
        errors,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label className="mb-0">Logo</Form.Label>
            <Form.Control
              id="logo"
              ref={fileInputRef}
              type="file"
              name="logo"
              onBlur={handleBlur}
              onChange={(e) => {
                setFieldValue("logo", e.currentTarget.files[0]);
              }}
              isInvalid={Boolean(touched.logo && errors.logo)}
              accept="image/png, image/jpeg"
              disabled={submitting}
            />
            <Form.Control.Feedback type="invalid">
              {errors.logo}
            </Form.Control.Feedback>
            {isEdit && (
              <Form.Check
                id="rcl"
                name="removeCurrentLogo"
                label={
                  <span className="cursor-pointer" htmlFor="rcl">
                    Remove Current Logo?
                  </span>
                }
                type="switch"
                onChange={handleChange}
                value={values.removeCurrentLogo}
                checked={values.removeCurrentLogo}
                disabled={isSubmitting}
              />
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="mb-0">
              Name<span className="text-danger ms-1">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="name"
              placeholder="Enter Name..."
              value={values.name}
              onBlur={handleBlur}
              onChange={handleChange}
              isInvalid={Boolean(touched.name && errors.name)}
              disabled={submitting}
            />
            <Form.Control.Feedback type="invalid">
              {errors.name}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="m-0">
              Phone Number<span className="text-danger ms-1">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="phoneNumber"
              placeholder="Enter Phone Number..."
              value={values.phoneNumber}
              onBlur={handleBlur}
              onChange={handleChange}
              isInvalid={Boolean(touched.phoneNumber && errors.phoneNumber)}
              disabled={submitting}
            />
            <Form.Control.Feedback type="invalid">
              {errors.phoneNumber}
            </Form.Control.Feedback>
          </Form.Group>

          <Stack direction="horizontal">
            <Button
              type="submit"
              variant="outline-primary"
              disabled={isSubmitting || !dirty}
            >
              {isSubmitting ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : isEdit ? (
                "Save"
              ) : (
                "Create"
              )}
            </Button>
            <div className="vr ms-3" />
            <Collapse in={dirty} dimension="width">
              <div>
                <Button
                  className="ms-3"
                  variant="outline-danger"
                  onClick={() => {
                    handleReset();
                    fileInputRef.current.value = "";
                  }}
                  disabled={isSubmitting || !dirty}
                >
                  Reset
                </Button>
              </div>
            </Collapse>
            <Button
              className="ms-3"
              variant="outline-secondary"
              onClick={() => {
                handleReset();
                setShowOrganizationModal(false);
              }}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default OrganizationForm;
