import axios from "axios";
import { handleError, buildQueryString, testDelay } from "../utils/helper";

const HOST_URL = process.env.REACT_APP_HOST_URL;

/**
 * Retrieves claim statuses from the server.
 *
 * @async
 * @function getClaimStatuses
 * @returns {Promise<Array<Object>>} A Promise that resolves to an array of claim status objects.
 * @throws {Error} If an error occurs during the API request.
 */
const getClaimStatuses = async () => {
  try {
    return (await axios.get(`${HOST_URL}/claims/statuses`)).data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Retrieves claims from the server based on the provided claimId.
 *
 * @async
 * @function getClaims
 * @param {Object} options - The options for retrieving claims.
 * @param {string} options.claimId - The claimId used to filter the claims. Optional.
 * @returns {Promise<Object>} A promise that resolves to the claims data.
 * @throws {Error} If an error occurs during the API request.
 */
const getClaims = async ({ claimId, reportId } = {}) => {
  const queryString = buildQueryString({ reportId });
  await testDelay(2000);

  try {
    if (claimId) {
      return (await axios.get(`${HOST_URL}/claims/${claimId}`)).data;
    } else {
      return (await axios.get(`${HOST_URL}/claims${queryString}`)).data;
    }
  } catch (error) {
    return handleError(error);
  }
};

const createClaim = async (claimData) => {
  console.log(claimData);
  try {
    return (await axios.post(`${HOST_URL}/claims`, claimData)).data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Updates a claim with extra fields from SellerClaims.
 *
 * @async
 * @function updateClaim
 * @param {number} claimId - The ID of the claim to update.
 * @param {Object} data - The data for the claim update.
 * @param {string} data.caseId - The SellerClaims case ID for the claim.
 * @param {string} data.reimbursementId - The SellerClaims reimbursement ID associated with the claim.
 * @param {number} data.statusId - The case status ID of claim.
 * @param {string} data.notes - The extra notes from a SellerClaims user about the case.
 * @param {number} data.assignedUserId - The ID of the SellerClaims user assigned to this claim.
 * @returns {Promise<Object>} A promise that resolves to the updated claim data.
 * @throws {Error} If an error occurs during the API request.
 */
const updateClaim = async (claimId, data) => {
  try {
    return (await axios.patch(`${HOST_URL}/claims/${claimId}`, data)).data;
  } catch (error) {
    return handleError(error);
  }
};

const services = {
  getClaimStatuses,
  getClaims,
  createClaim,
  updateClaim,
};

export default services;
