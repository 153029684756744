import { Row, Col } from "react-bootstrap";

import {
  LAYOUT,
  SIDEBAR_BEHAVIOR,
  SIDEBAR_POSITION,
  THEME,
} from "../../constants";

import useLayout from "../../hooks/useLayout";
import useSidebar from "../../hooks/useSidebar";
import useTheme from "../../hooks/useTheme";

const themeOptions = [
  {
    name: "Default",
    value: THEME.DEFAULT,
  },
  {
    name: "Colored",
    value: THEME.COLORED,
  },
  {
    name: "Dark",
    value: THEME.DARK,
  },
  {
    name: "Light",
    value: THEME.LIGHT,
  },
];

const sidebarPositionOptions = [
  {
    name: "Left",
    value: SIDEBAR_POSITION.LEFT,
  },
  {
    name: "Right",
    value: SIDEBAR_POSITION.RIGHT,
  },
];

const sidebarBehaviorOptions = [
  {
    name: "Sticky",
    value: SIDEBAR_BEHAVIOR.STICKY,
  },
  {
    name: "Fixed",
    value: SIDEBAR_BEHAVIOR.FIXED,
  },
  {
    name: "Compact",
    value: SIDEBAR_BEHAVIOR.COMPACT,
  },
];

const layoutOptions = [
  {
    name: "Fluid",
    value: LAYOUT.FLUID,
  },
  {
    name: "Boxed",
    value: LAYOUT.BOXED,
  },
];

const SiteSettings = () => {
  const { theme, setTheme } = useTheme();
  const { position, setPosition, behavior, setBehavior } = useSidebar();
  const { layout, setLayout } = useLayout();

  return (
    <>
      <h3>Site Settings</h3>
      <p className="text-muted mb-4">
        Change the look basic look of the ClaimsPro app.
      </p>
      <Row>
        <Col xl={6}>
          <hr />
          <div className="mb-3">
            <span className="d-block font-size-lg fw-bold">Color scheme</span>
            <span className="d-block text-muted mb-2">
              The perfect color mode for your app.
            </span>
            <div className="row g-0 text-center mx-n1 mb-2">
              {themeOptions.map(({ name, value }) => (
                <div className="col-6" key={value}>
                  <label className="mx-1 d-block mb-1">
                    <input
                      className="settings-scheme-label"
                      type="radio"
                      name="theme"
                      value={value}
                      checked={theme === value}
                      onChange={() => setTheme(value)}
                    />
                    <div className="settings-scheme">
                      <div
                        className={`settings-scheme-theme settings-scheme-theme-${value}`}
                      ></div>
                    </div>
                  </label>
                  {name}
                </div>
              ))}
            </div>
          </div>
          <hr />
          <div className="mb-3">
            <span className="d-block font-size-lg fw-bold">
              Sidebar position
            </span>
            <span className="d-block text-muted mb-2">
              Toggle the position of the sidebar.
            </span>
            <div>
              {sidebarPositionOptions.map(({ name, value }) => (
                <label className="me-1" key={value}>
                  <input
                    className="settings-button-label"
                    type="radio"
                    name="sidebarPosition"
                    value={value}
                    checked={position === value}
                    onChange={() => setPosition(value)}
                  />
                  <div className="settings-button">{name}</div>
                </label>
              ))}
            </div>
          </div>
          <hr />
          <div className="mb-3">
            <span className="d-block font-size-lg fw-bold">
              Sidebar behavior
            </span>
            <span className="d-block text-muted mb-2">
              Change the behavior of the sidebar.
            </span>
            <div>
              {sidebarBehaviorOptions.map(({ name, value }) => (
                <label className="me-1" key={value}>
                  <input
                    className="settings-button-label"
                    type="radio"
                    name="sidebarBehavior"
                    value={value}
                    checked={behavior === value}
                    onChange={() => setBehavior(value)}
                  />
                  <div className="settings-button">{name}</div>
                </label>
              ))}
            </div>
          </div>
          <hr />
          <div className="mb-3">
            <span className="d-block font-size-lg fw-bold">Layout</span>
            <span className="d-block text-muted mb-2">
              Toggle container layout system.
            </span>
            <div>
              {layoutOptions.map(({ name, value }) => (
                <label className="me-1" key={value}>
                  <input
                    className="settings-button-label"
                    type="radio"
                    name="layout"
                    value={value}
                    checked={layout === value}
                    onChange={() => setLayout(value)}
                  />
                  <div className="settings-button">{name}</div>
                </label>
              ))}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SiteSettings;
