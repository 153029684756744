import axios from "axios";
import { handleError, testDelay } from "../utils/helper";

const HOST_URL = process.env.REACT_APP_HOST_URL;

/**
 * Fetches organization(s) from the backend service.
 *
 * If `organizationId` is provided, fetches the specific organization by ID.
 * If `organizationId` is not provided, fetches all organizations.
 *
 * @param {number} [organizationId] - The optional ID of the organization to fetch.
 * @returns {Promise<Object>} A promise that resolves to the data of the organization(s).
 * @throws Will throw an error if the request fails.
 */
const getOrganizations = async (organizationId) => {
  try {
    const url = `${HOST_URL}/portal/organizations${
      organizationId ? `/${organizationId}` : ""
    }`;
    return (await axios.get(url)).data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Updates an organization's details in the backend service.
 *
 * @param {number} organizationId - The ID of the organization to update.
 * @param {Object} organizationData - The data to update the organization with.
 * @param {string} organizationData.key - The keys of this object represent the fields to update, and the values are the new data for those fields.
 * @returns {Promise<Object>} A promise that resolves to the updated organization data.
 * @throws Will throw an error if the request fails.
 */
const updateOrganization = async (organizationId, organizationData) => {
  // Create a FromData object and appends organization data to it.
  const formData = new FormData();
  Object.keys(organizationData).forEach((key) => {
    formData.append(key, organizationData[key]);
  });

  try {
    return (
      await axios.patch(
        `${HOST_URL}/portal/organizations/${organizationId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Creates a new organization in the backend service.
 *
 * @param {Object} organizationData - The data for the new organization.
 * @param {string} organizationData.key - The keys of this object represent the fields for the new organization, and the values are the data for those fields.
 * @returns {Promise<Object>} A promise that resolves to the created organization data.
 * @throws Will throw an error if the request fails.
 */
const createOrganization = async (organizationData) => {
  // Create a FromData object and appends organization data to it.
  const formData = new FormData();
  Object.keys(organizationData).forEach((key) => {
    formData.append(key, organizationData[key]);
  });

  try {
    return (
      await axios.post(`${HOST_URL}/portal/organizations`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Deactivates an organization in the backend service.
 *
 * @param {number} organizationId - The ID of the organization to deactivate.
 * @returns {Promise<Object>} A promise that resolves to the response data from the deactivation request.
 * @throws Will throw an error if the request fails.
 */
const deactivateOrganization = async (organizationId) => {
  try {
    return (
      await axios.delete(`${HOST_URL}/portal/organizations/${organizationId}`)
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

const services = {
  getOrganizations,
  updateOrganization,
  createOrganization,
  deactivateOrganization,
};

export default services;
