import { Formik } from "formik";
import { useState } from "react";
import { Alert, Button, Collapse, Form, InputGroup } from "react-bootstrap";
import { AlertOctagon, Smile, Eye, EyeOff } from "react-feather";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import useAuth from "../../hooks/useAuth";

import authService from "../../services/auth.service";

const SignUpForm = ({ token }) => {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        password: "",
        confirmPassword: "",
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string()
          .max(50, "First Name must be less than 50 characters long.")
          .required("First Name is required."),
        lastName: Yup.string()
          .max(50, "Last Name must be less than 50 characters long.")
          .required("Last Name is required."),
        password: Yup.string()
          .max(50, "Password can be at most 50 characters long.")
          .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/,
            "Password must contain at least one letter, one number, and one special character."
          )
          .when([], (schema) => schema.required("Password is required.")),
        confirmPassword: Yup.string().when("password", (password, schema) => {
          if (password) {
            return schema
              .required("Confirm Password is required.")
              .oneOf([Yup.ref("password"), null], "Passwords must match.");
          }
          return schema;
        }),
      })}
      onSubmit={async (values, { setErrors }) => {
        console.log(values);
        const response = await authService.signUp(token, values);

        if (response.success) {
          const { accessToken, refreshToken, user, organization } = response;
          signIn(accessToken, refreshToken, user, organization);

          navigate("/");
        } else {
          setErrors({ submit: response.message });
        }
      }}
    >
      {({
        handleBlur,
        handleChange,
        handleSubmit,
        errors,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert variant="danger" className="flex-fill alert-blink">
              <div className="alert-icon">
                <AlertOctagon
                  className="position-relative top-50 start-50 translate-middle"
                  size={20}
                />
              </div>
              <div className="alert-message font-weight-bold">
                {errors.submit}
              </div>
            </Alert>
          )}

          <Form.Group className="mb-3">
            <Form.Label className="mb-0">
              First Name<span className="text-danger ms-1">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="firstName"
              placeholder="Enter First Name..."
              value={values.firstName}
              onBlur={handleBlur}
              onChange={handleChange}
              isInvalid={Boolean(touched.firstName && errors.firstName)}
              disabled={isSubmitting}
            />
            <Form.Control.Feedback type="invalid">
              {errors.firstName}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="mb-0">
              Last Name<span className="text-danger ms-1">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="lastName"
              placeholder="Enter Last Name..."
              value={values.lastName}
              onBlur={handleBlur}
              onChange={handleChange}
              isInvalid={Boolean(touched.lastName && errors.lastName)}
              disabled={isSubmitting}
            />
            <Form.Control.Feedback type="invalid">
              {errors.lastName}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Label className="mb-0">
            Password<span className="text-danger ms-1">*</span>
          </Form.Label>
          <InputGroup hasValidation>
            <Form.Control
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="Enter Password..."
              value={values.password}
              onBlur={handleBlur}
              onChange={handleChange}
              isInvalid={Boolean(touched.password && errors.password)}
              disabled={isSubmitting}
            />
            <Button
              variant="outline-secondary"
              onClick={togglePassword}
              disabled={isSubmitting}
            >
              {showPassword ? <EyeOff /> : <Eye />}
            </Button>
            <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>
          </InputGroup>

          <Collapse in={Boolean(values.password)}>
            <div>
              <Form.Label className="mt-3">
                Confirm Password<span className="text-danger ms-1">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  name="confirmPassword"
                  placeholder="Re-Enter Password..."
                  value={values.confirmPassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isInvalid={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                  disabled={isSubmitting}
                />
                <Button
                  variant="outline-secondary"
                  onClick={togglePassword}
                  disabled={isSubmitting}
                >
                  {showPassword ? <EyeOff /> : <Eye />}
                </Button>
                <Form.Control.Feedback type="invalid">
                  {errors.confirmPassword}
                </Form.Control.Feedback>
              </InputGroup>
            </div>
          </Collapse>

          <div className="text-center mt-3">
            <Button type="submit" variant="primary" disabled={isSubmitting}>
              Sign Up
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SignUpForm;
