import React, { useEffect } from "react";
import { Card, Image } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";

import logo from "../../assets/img/claims_pro_logo_color.svg";
import SignUpForm from "../../components/auth/SignUpForm";
import { isValidToken } from "../../utils/jwt";

const SignUpPage = () => {
  const navigate = useNavigate();

  // Check to see if the token is valid before continuing.
  const { token } = useParams();

  useEffect(() => {
    if (token) {
      if (!isValidToken(token)) {
        navigate("/auth/400");
      }
    }
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Sign In" />
      <div className="d-flex flex-column">
        <Image src={logo} alt="ClaimsPro logo" className="img-fluid" />
        <p className="lead mt-1 align-self-end">by Returns Worldwide</p>
      </div>

      <Card>
        <Card.Body>
          <div className="text-center m-sm-4">
            <h2>Create Account</h2>
            <p className="lead">
              Welcome to ClaimsPro. Begin creating your account by entering your
              first name, last name, and a strong password.
            </p>
          </div>
          <div className="m-sm-4">
            <SignUpForm token={token} />
            <div className="text-center mt-4">
              <Card.Link
                onClick={() => {
                  navigate("/");
                }}
              >
                Already have an account?
              </Card.Link>
            </div>
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default SignUpPage;
