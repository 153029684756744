import React from "react";

import SidebarNavSection from "./SidebarNavSection";

import useAuth from "../../hooks/useAuth";

const SidebarNav = ({ items }) => {
  const { user } = useAuth();
  const isAdmin = user?.isAdmin;
  const isSuper = user?.isSuper;

  return (
    <ul className="sidebar-nav">
      {items && isSuper
        ? items.map((item, index) => (
            <SidebarNavSection
              key={index}
              pages={item.pages}
              title={item.title}
            />
          ))
        : items && isAdmin
        ? items
            .filter((item) => item.title !== "Super Administrator")
            .map((item, index) => (
              <SidebarNavSection
                key={index}
                pages={item.pages}
                title={item.title}
              />
            ))
        : items
            .filter(
              (item) =>
                item.title !== "Super Administrator" &&
                item.title !== "Administrator"
            )
            .map((item, index) => (
              <SidebarNavSection
                key={index}
                pages={item.pages}
                title={item.title}
              />
            ))}
    </ul>
  );
};

export default SidebarNav;
