import React, { useContext, useEffect, useState } from "react";
import { Alert, Card, Modal } from "react-bootstrap";
import { AlertOctagon, Smile } from "react-feather";

import OrganizationForm from "./OrganizationForm";

import { OrganizationsContext } from "./Organizations";

const OrganizationModal = ({ isEdit }) => {
  const [status, setStatus] = useState();

  const { showOrganizationModal } = useContext(OrganizationsContext);

  useEffect(() => {
    setStatus();
  }, [showOrganizationModal]);

  return (
    <Modal
      show={showOrganizationModal}
      onHide={() => setStatus()}
      backdrop="static"
      keyboard={false}
      size="sm"
    >
      {isEdit ? (
        <Modal.Header className="d-flex flex-column align-items-start">
          <Card.Title>Edit Organization</Card.Title>
          <Card.Subtitle className="text-muted">
            Change the logo, name, or phone number for the organization.
          </Card.Subtitle>
        </Modal.Header>
      ) : (
        <Modal.Header className="d-flex flex-column align-items-start">
          <Card.Title>Create Organization</Card.Title>
          <Card.Subtitle className="text-muted">
            Enter a name and phone number for the new organization.
          </Card.Subtitle>
        </Modal.Header>
      )}
      <Modal.Body>
        {status && (
          <Alert
            variant={status?.success ? "success" : "danger"}
            className="flex-fill alert-blink"
          >
            <div className="alert-icon">
              {status?.success ? (
                <Smile
                  className="position-relative top-50 start-50 translate-middle"
                  size={20}
                />
              ) : (
                <AlertOctagon
                  className="position-relative top-50 start-50 translate-middle"
                  size={20}
                />
              )}
            </div>
            <div className="alert-message font-weight-bold">
              {status.message}
            </div>
          </Alert>
        )}
        <OrganizationForm isEdit={isEdit} setStatus={setStatus} />
      </Modal.Body>
    </Modal>
  );
};

export default OrganizationModal;
