import { X, Check } from "react-feather";
import { Image } from "react-bootstrap";
import { faImage } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const tableColumns = [
  // {
  //   Header: "Avatar",
  //   accessor: "avatar",
  //   Cell: ({ value }) => {
  //     if (value) {
  //       return (
  //         <Image
  //           className="img-thumbnail"
  //           style={{ maxHeight: "75px" }}
  //           src={value}
  //           onError={({ currentTarget, error }) => {
  //             currentTarget.onerror = null; // prevents looping
  //             // currentTarget.src = imageNotFound;
  //             console.log(error);
  //           }}
  //           alt="User Avatar"
  //         />
  //       );
  //     } else {
  //       return <FontAwesomeIcon icon={faImage} />;
  //     }
  //   },
  //   filterable: false,
  //   searchable: false,
  // },
  {
    Header: "First Name",
    accessor: "firstName",
  },
  {
    Header: "Last Name",
    accessor: "lastName",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Organization",
    accessor: "organization",
  },
  {
    Header: "Administrator",
    accessor: "isAdmin",
    Cell: ({ value }) => {
      if (value) {
        return <Check color="#4BBF73" size={24} />;
      } else {
        return <X color="#d9534f" size={24} />;
      }
    },
    sortType: (row) => (row.original.isAdmin ? -1 : 1),
  },
  {
    Header: "Super User",
    accessor: "isSuper",
    Cell: ({ value }) => {
      if (value) {
        return <Check color="#4BBF73" size={24} />;
      } else {
        return <X color="#d9534f" size={24} />;
      }
    },
    sortType: (row) => (row.original.isActive ? -1 : 1),
  },
  {
    Header: "API Access",
    accessor: "hasApiAccess",
    Cell: ({ value }) => {
      if (value) {
        return <Check color="#4BBF73" size={24} />;
      } else {
        return <X color="#d9534f" size={24} />;
      }
    },
    sortType: (row) => (row.original.hasApiAccess ? -1 : 1),
  },
  {
    Header: "Active",
    accessor: "isActive",
    Cell: ({ value }) => {
      if (value) {
        return <Check color="#4BBF73" size={24} />;
      } else {
        return <X color="#d9534f" size={24} />;
      }
    },
    sortType: (row) => (row.original.isActive ? -1 : 1),
  },
];

export default tableColumns;
