import React, { createContext, useEffect, useState } from "react";
import { Col, Container, Row, Fade } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import Loader from "../../components/Loader";
import OrganizationDetails from "./OrganizationDetails";
import OrganizationsTable from "./OrganizationsTable";
import OrganizationModal from "./OrganizationModal";

import organizationsService from "../../services/organizations.service";

import useAuth from "../../hooks/useAuth";

const OrganizationsContext = createContext();

const OrganizationsPage = () => {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showOrganizationModal, setShowOrganizationModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [organizations, setOrganizations] = useState();
  const [selectedOrganization, setSelectedOrganization] = useState();

  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      getOrganizations();
    }
  }, [user]);

  const getOrganizations = async () => {
    setLoading(true);

    const result = await organizationsService.getOrganizations();

    if (result?.success) {
      const sortedUsers = result.organizations.sort((a, b) => {
        if (a.isActive && b.isActive) {
          return 0;
        }
        if (a.isActive) {
          return -1;
        }
        if (b.isActive) {
          return 1;
        }
        return a.isActive - b.isActive;
      });

      setOrganizations(sortedUsers);
    } else {
      console.log(result);
    }

    setLoading(false);
  };

  return (
    <OrganizationsContext.Provider
      value={{
        submitting,
        setSubmitting,
        showOrganizationModal,
        setShowOrganizationModal,
        setIsEdit,
        organizations,
        setOrganizations,
        selectedOrganization,
        setSelectedOrganization,
      }}
    >
      <Helmet title="Organizations" />
      <Container fluid className="p-0">
        <h1 className="mb-3">Organizations</h1>
        <Row>
          <Col xl={8}>
            {!organizations && loading && (
              <Fade in={loading}>
                <div>
                  <Loader />
                </div>
              </Fade>
            )}
            <Fade in={Boolean(organizations)}>
              <div>
                {organizations && (
                  <>
                    <OrganizationModal isEdit={isEdit} />
                    <OrganizationsTable
                      organizations={organizations}
                      setSelectedOrganization={setSelectedOrganization}
                    />
                  </>
                )}
              </div>
            </Fade>
          </Col>
          <Col xl={4}>
            <Fade in={Boolean(selectedOrganization)}>
              <div>{selectedOrganization && <OrganizationDetails />}</div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </OrganizationsContext.Provider>
  );
};

export { OrganizationsContext };

export default OrganizationsPage;
