import JSZip from "jszip";
import { Download, Images } from "lucide-react";
import { useContext, useState } from "react";
import {
  Button,
  Card,
  Carousel,
  Col,
  Image,
  Modal,
  Row,
} from "react-bootstrap";

import Loader from "../../components/Loader";

import { ClaimsContext } from "./Claims";

const ClaimImages = () => {
  const [showImageCarousel, setShowImageCarousel] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);

  const { loading, submitting, selectedClaim } = useContext(ClaimsContext);

  const downloadAllImages = async () => {
    const zip = new JSZip();

    // Add each base64 image to the ZIP archive
    selectedClaim.report.reportImages.forEach((image) => {
      const blob = dataURItoBlob(image.imageSrc);
      zip.file(`${image.imageId}.jpg`, blob);
    });

    // Generate the ZIP blob and create a download link
    const zipBlob = await zip.generateAsync({ type: "blob" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(zipBlob);
    link.download = `${selectedClaim.lpn}-claim-images.zip`;
    link.click();
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = window.atob(dataURI.split(",")[1]);
    const mimeType = dataURI.split(",")[0].split(":")[1].split(";")[0];

    const buffer = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      buffer[i] = byteString.charCodeAt(i);
    }

    return new Blob([buffer], { type: mimeType });
  };

  return (
    <>
      {selectedClaim.report.reportImages && (
        <Modal
          show={showImageCarousel}
          onHide={() => setShowImageCarousel(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Report Images</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Carousel
              indicators={true}
              controls={true}
              interval={null}
              activeIndex={carouselIndex}
              onSelect={(selectedIndex) => setCarouselIndex(selectedIndex)}
            >
              {selectedClaim.report.reportImages.map((image, index) => {
                return (
                  <Carousel.Item key={index}>
                    <Image src={`${image.imageSrc}`} fluid />
                    <Carousel.Caption>
                      <h3 className="text-white">{image.imageId}</h3>
                    </Carousel.Caption>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </Modal.Body>
        </Modal>
      )}
      <Card>
        <Card.Header className="border-bottom">
          <div className="d-flex justify-content-start mb-3">
            <Images className="me-3" size={48} />
            <div>
              <Card.Title>Report Images</Card.Title>
              <Card.Subtitle>
                Images associated with the selected claim's inspection report.
              </Card.Subtitle>
            </div>
          </div>
          <div className="d-flex">
            <Button
              className="flex-fill"
              variant="primary"
              onClick={() => downloadAllImages()}
              disabled={submitting}
            >
              <Download className="me-2" size={18} />
              Download Images
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          {loading ? (
            <Loader />
          ) : (
            <Row>
              {selectedClaim.report.reportImages &&
                selectedClaim.report.reportImages.map((image, index) => {
                  return (
                    <Col key={index} xs={6} className="mb-3">
                      <Image
                        src={`${image.imageSrc}`}
                        fluid
                        onClick={() => {
                          setShowImageCarousel(true);
                          setCarouselIndex(index);
                        }}
                        className="cursor-pointer border"
                      />
                    </Col>
                  );
                })}
            </Row>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default ClaimImages;
