import { Formik } from "formik";
import { useState } from "react";
import { Alert, Button, Col, Fade, Form, Row, Stack } from "react-bootstrap";
import { AlertOctagon, Smile } from "react-feather";
import toast from "react-hot-toast";
import * as Yup from "yup";

import useAuth from "../../hooks/useAuth";

import authService from "../../services/auth.service";
import usersService from "../../services/users.service";

const AccountSettings = ({ formikRef }) => {
  const { user, updateUser } = useAuth();

  // For disabling the reset password button.
  const [submitting, setSubmitting] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  //Global Account Settings Status alert
  const [status, setStatus] = useState();

  const handleResetPassword = async () => {
    setStatus();
    setSubmitting(true);

    const response = await authService.sendOtp(user.email);

    if (response.success) {
      toast.success("Reset password email successfully sent!");

      setEmailSent(true);
      setStatus(response);
    } else {
      toast.error("Uh oh, something went wrong!");

      setStatus({ success: false, message: response.message });
    }
    setSubmitting(false);
  };

  return (
    <>
      <h3>Account Settings</h3>
      <p className="text-muted">Change your basic account settings.</p>
      <Row>
        <Col xl={6}>
          {status?.success && (
            <Alert variant="success" className="flex-fill alert-blink">
              <div className="alert-icon">
                <Smile
                  className="position-relative top-50 start-50 translate-middle"
                  size={20}
                />
              </div>
              <div className="alert-message font-weight-bold">
                {status.message}
              </div>
            </Alert>
          )}
          {status?.success === false && (
            <Alert variant="danger" className="flex-fill alert-blink">
              <div className="alert-icon">
                <AlertOctagon
                  className="position-relative top-50 start-50 translate-middle"
                  size={20}
                />
              </div>
              <div className="alert-message font-weight-bold">
                {status.message}
              </div>
            </Alert>
          )}
          <hr />
          <Formik
            innerRef={formikRef}
            enableReinitialize
            initialValues={{
              firstName: user?.firstName || "",
              lastName: user?.lastName || "",
              email: user?.email || "",
            }}
            initialStatus={{ success: false }}
            validationSchema={Yup.object().shape({
              firstName: Yup.string()
                .max(50, "Full Name must be less than 50 characters long.")
                .required("Full Name is required."),
              lastName: Yup.string()
                .max(50, "Full Name must be less than 50 characters long.")
                .required("Full Name is required."),
              email: Yup.string()
                .email("Invalid email.")
                .max(255)
                .required("Email is required."),
            })}
            onSubmit={async (values, { setErrors }) => {
              const response = await usersService.updateUser(
                user.userId,
                values
              );

              if (response.success) {
                toast.success("Your account settings have been updated!");
                setStatus({
                  success: true,
                  message: "Your account settings have been updated!",
                });

                // Remove the organization object before updating the JWTContext user.
                delete response.updatedUser.organization;
                updateUser(response.accessToken, response.updatedUser);
              } else {
                toast.error("Something went wrong.");
                setStatus({ success: false });
                setErrors({ submit: response.message });
              }
            }}
          >
            {({
              handleSubmit,
              handleReset,
              handleChange,
              handleBlur,
              touched,
              dirty,
              isSubmitting,
              errors,
              values,
            }) => (
              <Form onSubmit={handleSubmit}>
                <span className="d-block font-size-lg fw-bold">
                  General Info
                </span>
                <span className="d-block text-muted mb-2">
                  Update your name or email.
                </span>

                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">
                    First Name<span className="text-danger ms-1">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="firstName"
                    placeholder="Enter First Name..."
                    value={values.firstName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isInvalid={Boolean(touched.firstName && errors.firstName)}
                    disabled={isSubmitting}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.firstName}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">
                    Last Name<span className="text-danger ms-1">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="lastName"
                    placeholder="Enter Last Name..."
                    value={values.lastName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isInvalid={Boolean(touched.lastName && errors.lastName)}
                    disabled={isSubmitting}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.lastName}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label className="m-0">
                    Email<span className="text-danger ms-1">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    placeholder="Enter Email..."
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isInvalid={Boolean(touched.email && errors.email)}
                    disabled={isSubmitting}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>

                <Fade in={dirty}>
                  <Stack direction="horizontal" gap={3}>
                    <Button
                      type="submit"
                      variant="outline-primary"
                      disabled={isSubmitting || !dirty}
                    >
                      Save Changes
                    </Button>
                    <div className="vr" />
                    <Button
                      variant="outline-danger"
                      onClick={handleReset}
                      disabled={isSubmitting || !dirty}
                    >
                      Reset
                    </Button>
                  </Stack>
                </Fade>
              </Form>
            )}
          </Formik>

          <hr />
          <span className="d-block font-size-lg fw-bold">Password</span>
          <span className="d-block text-muted mb-2">Change your password.</span>
          <Button
            className="d-block"
            variant="primary"
            onClick={handleResetPassword}
            disabled={submitting || emailSent}
          >
            {emailSent ? "Email Sent" : "Send Reset Email"}
          </Button>
          <Form.Text>
            Check your email for an OTP code and a reset link.
          </Form.Text>
        </Col>
      </Row>
    </>
  );
};

export default AccountSettings;
