import React, { createContext, useEffect, useState } from "react";
import { Col, Container, Fade, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

import Loader from "../../components/Loader";
import ReportDetails from "./ReportDetails";
import ReportImages from "./ReportImages";
import ReportsTable from "./ReportsTable";

import { handleScroll } from "../../utils/helper";

import reportsService from "../../services/reports.service";
import userService from "../../services/users.service";

import useAuth from "../../hooks/useAuth";

const ReportsContext = createContext();

const Reports = () => {
  const { user, organization } = useAuth();

  const isSuper = user?.isSuper;
  const isAdmin = user?.isAdmin;

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [reports, setReports] = useState();
  const [selectedReport, setSelectedReport] = useState();

  const [userOptions, setUserOptions] = useState();

  const { reportId } = useParams();

  useEffect(() => {
    if (user) {
      initialize();
    }
  }, [user]);

  useEffect(() => {
    if (reportId && reports && userOptions) {
      setSelectedReport(
        reports.find((report) => Number(report.reportId) === Number(reportId))
      );
    }
  }, [reports, userOptions]);

  useEffect(() => {
    handleScroll("report-details");
  }, [selectedReport]);

  const initialize = async () => {
    setLoading(true);
    await getReports();
    await getUserOptions();
    setLoading(false);
  };

  const getReports = async () => {
    const result = await reportsService.getReports();

    if (result.success) {
      if (!isAdmin) {
        const filteredReports = result.reports
          .filter(
            (report) =>
              report.assignedUserId === user.userId || !report.assignedUserId
          )
          .sort((a, b) => {
            if (a.assignedUserId && b.assignedUserId) {
              return 0;
            }
            if (a.assignedUserId) {
              return -1;
            }
            if (b.assignedUserId) {
              return 1;
            }
            return a.assignedUserId - b.assignedUserId;
          });
        setReports(filteredReports);
      } else {
        setReports(result.reports);
      }
    } else {
      console.log(result);
      toast.error("Something went wrong, please refresh the page.", {
        duration: 30000,
      });
    }
  };

  const getUserOptions = async () => {
    const response = await userService.getUsers();

    if (response.success) {
      if (isAdmin) {
        const activeUsers = response.users.filter((user) => user.isActive);
        const options = [
          {
            value: "",
            label: "Select a team member...",
          },
          {
            value: -1,
            label: "Unassigned",
          },
        ].concat(
          activeUsers.map((user) => {
            return {
              value: user.userId,
              label: `${user.firstName} ${user.lastName}`,
            };
          })
        );
        setUserOptions(options);
      } else {
        const options = [
          {
            value: "",
            label: "Select a team member...",
          },
          {
            value: -1,
            label: "Unassigned",
          },
          {
            value: user.userId,
            label: `${user.firstName} ${user.lastName}`,
          },
        ];
        setUserOptions(options);
      }
    } else {
      console.log(response);
      toast.error("Something went wrong, please refresh the page.", {
        duration: 30000,
      });
    }
  };

  return (
    <ReportsContext.Provider
      value={{
        isSuper,
        isAdmin,
        user,
        organization,
        submitting,
        setSubmitting,
        reports,
        setReports,
        selectedReport,
        setSelectedReport,
        userOptions,
      }}
    >
      <Helmet title="Inspection Reports" />
      <Container fluid className="p-0">
        <h1 className="mb-3">Inspection Reports</h1>

        <Row>
          <Col xl={8}>
            {!reports && loading && (
              <Fade in={loading}>
                <div>
                  <Loader />
                </div>
              </Fade>
            )}
            <Fade in={Boolean(reports)}>
              <div>{reports && <ReportsTable />}</div>
            </Fade>
            <Fade in={Boolean(selectedReport)}>
              <div id="report-details">
                {selectedReport && <ReportDetails />}
              </div>
            </Fade>
          </Col>

          <Col xl={4}>
            <Fade in={Boolean(selectedReport)}>
              <div className="sticky-top" style={{ top: 20 }}>
                {selectedReport && <ReportImages />}
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </ReportsContext.Provider>
  );
};

export { ReportsContext };

export default Reports;
