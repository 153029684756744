import React, { useContext } from "react";
import { Button, Card } from "react-bootstrap";

import Table from "../../components/table/Table";
import tableColumns from "./tableColumns";

import { Building2, Hospital } from "lucide-react";
import { OrganizationsContext } from "./Organizations";

const OrganizationsTable = () => {
  const {
    organizations,
    setSelectedOrganization,
    setShowOrganizationModal,
    setIsEdit,
  } = useContext(OrganizationsContext);

  const handleRowClick = async (row) => {
    setSelectedOrganization(row);
  };

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <div className="d-flex justify-content-start">
            <Building2 size={48} className="me-3" />
            <div>
              <Card.Title>Organizations Table</Card.Title>
              <Card.Subtitle>
                Click on an organization to view and edit it's details.
              </Card.Subtitle>
            </div>
          </div>
          <Button
            variant="primary"
            onClick={() => {
              setSelectedOrganization();
              setIsEdit(false);
              setShowOrganizationModal(true);
            }}
          >
            <Hospital size={18} className="me-2" />
            Create Organization
          </Button>
        </Card.Header>
        <Card.Body>
          <Table
            columns={tableColumns}
            data={organizations}
            onClickFn={handleRowClick}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default OrganizationsTable;
