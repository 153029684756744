import { DropdownFilter } from "../../components/table/filters";

const tableColumns = [
  {
    Header: "Claim ID",
    accessor: "claimId",
  },
  {
    Header: "Case ID",
    accessor: "caseId",
    Cell: ({ value }) => {
      if (value) {
        return value;
      } else {
        return "TBD";
      }
    },
  },
  {
    Header: "Reimbursement ID",
    accessor: "reimbursementId",
    Cell: ({ value }) => {
      if (value) {
        return value;
      } else {
        return "TBD";
      }
    },
  },
  {
    Header: "Amount",
    accessor: "amount",
    Cell: ({ value }) => {
      return Number(value).toFixed(2);
    },
  },
  {
    Header: "Status",
    accessor: "status",
    Filter: DropdownFilter,
  },
  {
    Header: "Creator",
    accessor: "user",
  },
  {
    Header: "Organization",
    accessor: "organization",
  },
  {
    Header: "Created At",
    accessor: "createdAt",
    Cell: ({ value }) => {
      return <span>{new Date(value).toLocaleString()}</span>;
    },
  },
  {
    Header: "Updated At",
    accessor: "updatedAt",
    Cell: ({ value }) => {
      return <span>{new Date(value).toLocaleString()}</span>;
    },
  },
];

export default tableColumns;
