import { createContext, useEffect, useReducer } from "react";

import { isValidToken, setSession, updateSession } from "../utils/jwt";
import authService from "../services/auth.service";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const UPDATE_USER = "UPDATE_USER";
const UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  organization: null,
};

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        organization: action.payload.organization,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        organization: action.payload.organization,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        organization: null,
      };
    case UPDATE_USER:
      return {
        ...state,
        user: action.payload.user,
      };
    case UPDATE_ORGANIZATION:
      return {
        ...state,
        organization: action.payload.organization,
      };
    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      console.log("Initializing...");
      try {
        const accessToken = localStorage.getItem("accessToken");
        const refreshToken = localStorage.getItem("refreshToken");

        if (isValidToken(accessToken)) {
          console.log("Access Token is valid.");

          setSession(accessToken, refreshToken);

          const response = await authService.getSession();

          const { user, accessToken: newAccessToken, organization } = response;

          if (newAccessToken) {
            setSession(newAccessToken, refreshToken);
          }

          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
              organization,
            },
          });
        } else {
          console.log("Access Token is not valid.");

          if (isValidToken(refreshToken)) {
            console.log("Refresh Token is valid.");

            setSession(accessToken, refreshToken);

            const response = await authService.getSession();

            if (response.success) {
              const {
                user,
                accessToken: newAccessToken,
                organization,
              } = response;

              if (newAccessToken) {
                setSession(newAccessToken, refreshToken);
              }

              dispatch({
                type: INITIALIZE,
                payload: {
                  isAuthenticated: true,
                  user,
                  organization,
                },
              });
            } else {
              setSession(null);
              dispatch({
                type: INITIALIZE,
                payload: {
                  isAuthenticated: false,
                  user: null,
                  organization: null,
                },
              });
            }
          } else {
            console.log("Refresh Token is not valid.");

            dispatch({
              type: INITIALIZE,
              payload: {
                isAuthenticated: false,
                user: null,
                organization: null,
              },
            });
          }
        }
      } catch (error) {
        console.error(error);

        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
            organization: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const signIn = async (accessToken, refreshToken, user, organization) => {
    if (!refreshToken) {
      throw new Error("No refresh token.");
    }

    // Set the local storage and authenticate user.
    setSession(accessToken, refreshToken);
    dispatch({
      type: SIGN_IN,
      payload: {
        user,
        organization,
      },
    });
  };

  const signOut = async () => {
    const refreshToken = localStorage.getItem("refreshToken");

    // Invalidate the current session.
    await authService.invalidateSession(refreshToken);

    // Clear the local storage and de-authenticate user.
    setSession(null);
    dispatch({ type: SIGN_OUT });
  };

  // Updates the logged in user.
  const updateUser = async (accessToken, user) => {
    if (!accessToken) {
      throw new Error("No accessToken token.");
    }

    updateSession(accessToken);
    dispatch({
      type: UPDATE_USER,
      payload: {
        user,
      },
    });
  };

  // Updates the logged in organization.
  const updateOrganization = async (organization) => {
    dispatch({
      type: UPDATE_ORGANIZATION,
      payload: {
        organization,
      },
    });
  };

  const checkExpiredRefreshToken = async () => {
    console.log("Checking Refresh Token...");
    const refreshToken = localStorage.getItem("refreshToken");

    if (!isValidToken(refreshToken)) {
      console.log("Refresh Token was not valid.");
      signOut();
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signOut,
        updateUser,
        updateOrganization,
        checkExpiredRefreshToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
