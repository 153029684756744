export const getCaseBody = (subReasonId, removalOrderId, lpn, fnsku) => {
  const caseBodies = {
    1: `Please reimburse 1 unit of FNSKU: ${fnsku} - The packaging shows signs of damage and is not sellable.\n\nRemoval Order ID: ${removalOrderId}\nLPN: ${lpn}\nFNSKU: ${fnsku}\n\nPlease issue a full reimbursement. Thank you!`,
    2: `Please reimburse 1 unit of FNSKU: ${fnsku} - The item exhibits significant wear and tear and is not sellable.\n\nRemoval Order ID: ${removalOrderId}\nLPN: ${lpn}\nFNSKU: ${fnsku}\n\nPlease issue a full reimbursement. Thank you!`,
    3: `Please reimburse 1 unit of FNSKU: ${fnsku} - The item displays stains, scratches, rust, dents, or bends and is not sellable.\n\nRemoval Order ID: ${removalOrderId}\nLPN: ${lpn}\nFNSKU: ${fnsku}\n\nPlease issue a full reimbursement. Thank you!`,
    4: `Please reimburse 1 unit of FNSKU: ${fnsku} - The seal of the product packaging has been broken and is not sellable.\n\nRemoval Order ID: ${removalOrderId}\nLPN: ${lpn}\nFNSKU: ${fnsku}\n\nPlease issue a full reimbursement. Thank you!`,
    5: `Please reimburse 1 unit of FNSKU: ${fnsku} - The item is part of an incomplete set and is not sellable.\n\nRemoval Order ID: ${removalOrderId}\nLPN: ${lpn}\nFNSKU: ${fnsku}\n\nPlease issue a full reimbursement. Thank you!`,
    6: `Please reimburse 1 unit of FNSKU: ${fnsku} - The item is missing some of its volume and is not sellable.\n\nRemoval Order ID: ${removalOrderId}\nLPN: ${lpn}\nFNSKU: ${fnsku}\n\nPlease issue a full reimbursement. Thank you!`,
    7: `Please reimburse 1 unit of FNSKU: ${fnsku} - The item is missing wires and is not sellable.\n\nRemoval Order ID: ${removalOrderId}\nLPN: ${lpn}\nFNSKU: ${fnsku}\n\nPlease issue a full reimbursement. Thank you!`,
    8: `Please reimburse 1 unit of FNSKU: ${fnsku} - The item is missing batteries and is not sellable.\n\nRemoval Order ID: ${removalOrderId}\nLPN: ${lpn}\nFNSKU: ${fnsku}\n\nPlease issue a full reimbursement. Thank you!`,
    9: `Please reimburse 1 unit of FNSKU: ${fnsku} - The item is missing screws and is not sellable.\n\nRemoval Order ID: ${removalOrderId}\nLPN: ${lpn}\nFNSKU: ${fnsku}\n\nPlease issue a full reimbursement. Thank you!`,
    10: `Please reimburse 1 unit of FNSKU: ${fnsku} - The item is missing the battery component cover and is not sellable.\n\nRemoval Order ID: ${removalOrderId}\nLPN: ${lpn}\nFNSKU: ${fnsku}\n\nPlease issue a full reimbursement. Thank you!`,
    11: `Please reimburse 1 unit of FNSKU: ${fnsku} - The item is missing toner and is not sellable.\n\nRemoval Order ID: ${removalOrderId}\nLPN: ${lpn}\nFNSKU: ${fnsku}\n\nPlease issue a full reimbursement. Thank you!`,
    12: `Please reimburse 1 unit of FNSKU: ${fnsku} - The item is missing SOMETHING-HERE and is not sellable.\n\nRemoval Order ID: ${removalOrderId}\nLPN: ${lpn}\nFNSKU: ${fnsku}\n\nPlease issue a full reimbursement. Thank you!`,
    13: `Please reimburse 1 unit of FNSKU: ${fnsku} - The received item is a different model and is not sellable.\n\nRemoval Order ID: ${removalOrderId}\nLPN: ${lpn}\nFNSKU: ${fnsku}\n\nPlease issue a full reimbursement. Thank you!`,
    14: `Please reimburse 1 unit of FNSKU: ${fnsku} - The received item is completely different and is not sellable.\n\nRemoval Order ID: ${removalOrderId}\nLPN: ${lpn}\nFNSKU: ${fnsku}\n\nPlease issue a full reimbursement. Thank you!`,
    15: `Please reimburse 1 unit of FNSKU: ${fnsku} - The received item is a different color and is not sellable.\n\nRemoval Order ID: ${removalOrderId}\nLPN: ${lpn}\nFNSKU: ${fnsku}\n\nPlease issue a full reimbursement. Thank you!`,
    16: `Please reimburse 1 unit of FNSKU: ${fnsku} - The received item has different serial number and is not sellable.\n\nRemoval Order ID: ${removalOrderId}\nLPN: ${lpn}\nFNSKU: ${fnsku}\n\nPlease issue a full reimbursement. Thank you!`,
    17: `Please reimburse 1 unit of FNSKU: ${fnsku} - The received item has different model number and is not sellable.\n\nRemoval Order ID: ${removalOrderId}\nLPN: ${lpn}\nFNSKU: ${fnsku}\n\nPlease issue a full reimbursement. Thank you!`,
  };

  return caseBodies[subReasonId];
};
