import React, { useContext, useRef, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Modal,
  Row,
  Form,
  Fade,
  Stack,
  Collapse,
  InputGroup,
} from "react-bootstrap";
import { AlertOctagon, FilePlus } from "lucide-react";
import { Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import toast from "react-hot-toast";

import claimsService from "../../services/claims.service";

import { ReportsContext } from "./Reports";

const ClaimModal = ({ showClaimModal, setShowClaimModal }) => {
  const [error, setError] = useState();

  const {
    isSuper,
    isAdmin,
    user,
    organization,
    submitting,
    setSubmitting,
    reports,
    setReports,
    selectedReport,
    setSelectedReport,
    userOptions,
  } = useContext(ReportsContext);

  const { lpn, reportId, organizationId } = selectedReport;

  return (
    <Modal
      show={showClaimModal}
      onHide={() => setShowClaimModal(false)}
      backdrop="static"
      keyboard={false}
      size="sm"
    >
      <Modal.Header>
        <div className="d-flex justify-content-start mb-3">
          <FilePlus
            className="me-2"
            style={{ width: "48px", height: "48px" }}
          />
          <div>
            <Card.Title>Create Claim</Card.Title>
            <Card.Subtitle>
              Create a new claim. Optionally add the claim's Case ID,
              Reimbursement ID, Amount, or Notes.
            </Card.Subtitle>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="">
        {error && (
          <Alert variant="danger" className="flex-fill alert-blink mb-3">
            <div className="alert-icon">
              <AlertOctagon
                className="position-relative top-50 start-50 translate-middle"
                size={20}
              />
            </div>
            <div className="alert-message font-weight-bold">
              {error.message}
            </div>
          </Alert>
        )}
        <Formik
          initialValues={{
            caseId: "",
            reimbursementId: "",
            amount: "",
            notes: "",
          }}
          validationSchema={Yup.object().shape({
            amount: Yup.string().matches(
              /^\d+(\.\d{1,2})?$/,
              "Invalid amount. Ex: 123 or 123.45"
            ),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);

            const claimData = {
              reportId,
              userId: user.userId,
              organizationId,
              ...values,
            };

            const response = await claimsService.createClaim(claimData);

            console.log(response);

            if (response.success) {
              toast.success("Claim has successfully been created!");

              setReports((prevState) => {
                return prevState.map((stateReport) => {
                  if (stateReport.reportId === response.newClaim.reportId) {
                    stateReport = response.updatedReport;
                  }
                  return stateReport;
                });
              });
              setSelectedReport(response.updatedReport);
              setShowClaimModal(false);
            } else {
              toast.error("Something went wrong. Please try again.");
              setError(response);
            }

            setSubmitting(false);
          }}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            touched,
            dirty,
            isSubmitting,
            errors,
            values,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label className="mb-0">Case ID</Form.Label>
                <Form.Control
                  type="text"
                  name="caseId"
                  placeholder="Enter Case ID..."
                  value={values.caseId}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isInvalid={Boolean(touched.caseId && errors.caseId)}
                  disabled={isSubmitting}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.caseId}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="mb-0">Reimbursement ID</Form.Label>
                <Form.Control
                  type="text"
                  name="reimbursementId"
                  placeholder="Enter Reimbursement ID..."
                  value={values.reimbursementId}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isInvalid={Boolean(
                    touched.reimbursementId && errors.reimbursementId
                  )}
                  disabled={isSubmitting}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.reimbursementId}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Label className="m-0">Amount</Form.Label>
              <InputGroup className="mb-3" hasValidation>
                <InputGroup.Text>$</InputGroup.Text>
                <Form.Control
                  type="text"
                  name="amount"
                  placeholder="Enter Amount..."
                  value={values.amount}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isInvalid={Boolean(touched.amount && errors.amount)}
                  disabled={isSubmitting}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.amount}
                </Form.Control.Feedback>
              </InputGroup>

              <Form.Group className="mb-3">
                <Form.Label className="m-0">Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  style={{ resize: "none" }}
                  name="notes"
                  placeholder="Additional Notes..."
                  // onChange={(e) => {
                  //   setNote(e.target.value);
                  // }}
                  value={values.notes}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isInvalid={Boolean(touched.notes && errors.notes)}
                  disabled={isSubmitting}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.notes}
                </Form.Control.Feedback>
              </Form.Group>

              <Stack direction="horizontal">
                <Button
                  type="submit"
                  variant="outline-primary"
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
                <div className="vr ms-3" />
                <Collapse in={dirty} dimension="width">
                  <div>
                    <Button
                      className="ms-3"
                      variant="outline-danger"
                      onClick={handleReset}
                      disabled={isSubmitting || !dirty}
                    >
                      Reset
                    </Button>
                  </div>
                </Collapse>
                <Button
                  className="ms-3"
                  variant="outline-secondary"
                  onClick={() => {
                    handleReset();
                    setShowClaimModal(false);
                  }}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default ClaimModal;
