import React, { useState, useRef } from "react";
import { Copy } from "lucide-react";
import { Tooltip, Overlay, Button } from "react-bootstrap";

import CopyToClipboard from "react-copy-to-clipboard";

const CopyButton = ({ text, ...props }) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <>
      <CopyToClipboard
        text={text}
        onCopy={() => {
          setShow(true);
          setTimeout(() => {
            setShow(false);
          }, 1000);
        }}
      >
        <Button ref={target} {...props}>
          <Copy size={18} />
        </Button>
      </CopyToClipboard>
      <Overlay target={target.current} show={show}>
        {(props) => <Tooltip {...props}>Copied!</Tooltip>}
      </Overlay>
    </>
  );
};

export default CopyButton;
