import { Formik } from "formik";
import { useRef } from "react";
import { Alert, Button, Form, InputGroup } from "react-bootstrap";
import { AlertOctagon } from "react-feather";
import * as Yup from "yup";

import authService from "../../services/auth.service";

const NUMBER_REGEX = /^[0-9]$/;

function VerifyOtp({ token, setValidated }) {
  const inputRefs = useRef([]);

  // This is for auto focusing the next input after a number is pressed.
  const handleKeyUp = (index, e) => {
    const key = e.key;
    const isNumberKey = NUMBER_REGEX.test(key);

    if (isNumberKey) {
      const nextIndex = (index + 1) % inputRefs.current.length; // Calculate next index
      inputRefs.current[nextIndex].focus(); // Focus on the next input
    }
  };

  return (
    <>
      <div className="text-center">
        <h2>Reset Password</h2>
        <p>
          Please enter the 6-digit verification code that was sent to your
          email.
        </p>
      </div>
      <Formik
        initialValues={{
          d0: "",
          d1: "",
          d2: "",
          d3: "",
          d4: "",
          d5: "",
        }}
        validationSchema={Yup.object().shape({
          d0: Yup.string()
            .matches(NUMBER_REGEX, "Digits are required")
            .required("Digits are required"),
          d1: Yup.string()
            .matches(NUMBER_REGEX, "Digits are required")
            .required("Digits are required"),
          d2: Yup.string()
            .matches(NUMBER_REGEX, "Digits are required")
            .required("Digits are required"),
          d3: Yup.string()
            .matches(NUMBER_REGEX, "Digits are required")
            .required("Digits are required"),
          d4: Yup.string()
            .matches(NUMBER_REGEX, "Digits are required")
            .required("Digits are required"),
          d5: Yup.string()
            .matches(NUMBER_REGEX, "Digits are required")
            .required("Digits are required"),
        })}
        onSubmit={async (values, { setErrors }) => {
          const otpCode = `${values.d0}${values.d1}${values.d2}${values.d3}${values.d4}${values.d5}`;

          const response = await authService.verifyOtp(token, otpCode);

          if (response.success) {
            setValidated(true);
          } else {
            setErrors({ submit: response.message });
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <Form onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert variant="danger" className="flex-fill alert-blink">
                <div className="alert-icon">
                  <AlertOctagon
                    className="position-relative top-50 start-50 translate-middle"
                    size={20}
                  />
                </div>
                <div className="alert-message font-weight-bold">
                  {errors.submit}
                </div>
              </Alert>
            )}

            <InputGroup className="mb-3" hasValidation>
              <Form.Control
                ref={(input) => inputRefs.current.push(input)}
                className="text-center"
                size="lg"
                name="d0"
                type="text"
                maxLength={1}
                value={values.d0}
                isInvalid={Boolean(touched.d0 && errors.d0)}
                onBlur={handleBlur}
                onChange={handleChange}
                onKeyUp={(e) => handleKeyUp(0, e)}
                disabled={isSubmitting}
              />
              <Form.Control
                ref={(input) => inputRefs.current.push(input)}
                className="text-center"
                size="lg"
                name="d1"
                type="text"
                maxLength={1}
                value={values.d1}
                isInvalid={Boolean(touched.d1 && errors.d1)}
                onBlur={handleBlur}
                onChange={handleChange}
                onKeyUp={(e) => handleKeyUp(1, e)}
                disabled={isSubmitting}
              />
              <Form.Control
                ref={(input) => inputRefs.current.push(input)}
                className="text-center"
                size="lg"
                name="d2"
                type="text"
                maxLength={1}
                value={values.d2}
                isInvalid={Boolean(touched.d2 && errors.d2)}
                onBlur={handleBlur}
                onChange={handleChange}
                onKeyUp={(e) => handleKeyUp(2, e)}
                disabled={isSubmitting}
              />
              <Form.Control
                ref={(input) => inputRefs.current.push(input)}
                className="text-center"
                size="lg"
                name="d3"
                type="text"
                maxLength={1}
                value={values.d3}
                isInvalid={Boolean(touched.d3 && errors.d3)}
                onBlur={handleBlur}
                onChange={handleChange}
                onKeyUp={(e) => handleKeyUp(3, e)}
                disabled={isSubmitting}
              />
              <Form.Control
                ref={(input) => inputRefs.current.push(input)}
                className="text-center"
                size="lg"
                name="d4"
                type="text"
                maxLength={1}
                value={values.d4}
                isInvalid={Boolean(touched.d4 && errors.d4)}
                onBlur={handleBlur}
                onChange={handleChange}
                onKeyUp={(e) => handleKeyUp(4, e)}
                disabled={isSubmitting}
              />
              <Form.Control
                ref={(input) => inputRefs.current.push(input)}
                className="text-center"
                size="lg"
                name="d5"
                type="text"
                maxLength={1}
                value={values.d5}
                isInvalid={Boolean(touched.d5 && errors.d5)}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
              />
              <Form.Control.Feedback type="invalid">
                {errors.d0 ||
                  errors.d1 ||
                  errors.d2 ||
                  errors.d3 ||
                  errors.d4 ||
                  errors.d5}
              </Form.Control.Feedback>
            </InputGroup>

            <div className="text-center mt-3">
              <Button type="submit" variant="primary" disabled={isSubmitting}>
                Verify Account
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default VerifyOtp;
