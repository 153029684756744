import React from "react";
import { Button } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

const Page400 = () => (
  <React.Fragment>
    <Helmet title="400 Error" />
    <div className="text-center">
      <h1 className="display-1 fw-bold">400</h1>
      <p className="h1">Bad Request.</p>
      <p className="h2 fw-normal mt-3 mb-4">
        Sorry, the link you followed is invalid or has expired. This can happen
        if the link was copied incorrectly, if it has already been used, or if
        it has expired for security reasons. Please check the link and try
        again. If you continue to experience issues, please contact our support
        team for assistance.
      </p>
      <Link to="/">
        <Button variant="primary" size="lg">
          Return to website
        </Button>
      </Link>
    </div>
  </React.Fragment>
);

export default Page400;
