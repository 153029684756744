import { Formik } from "formik";
import { useState } from "react";
import { Alert, Button, Form, InputGroup } from "react-bootstrap";
import { AlertOctagon, Eye, EyeOff } from "react-feather";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import useAuth from "../../hooks/useAuth";

import authService from "../../services/auth.service";

const SignInForm = () => {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Invalid email.")
          .max(255)
          .required("Email is required."),
        password: Yup.string().max(255).required("Password is required."),
      })}
      onSubmit={async (values, { setErrors }) => {
        const response = await authService.createSession(
          values.email,
          values.password
        );

        if (response.success) {
          const { accessToken, refreshToken, user, organization } = response;
          signIn(accessToken, refreshToken, user, organization);

          navigate("/");
        } else {
          setErrors({ submit: response.message });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert variant="danger" className="flex-fill alert-blink">
              <div className="alert-icon">
                <AlertOctagon
                  className="position-relative top-50 start-50 translate-middle"
                  size={20}
                />
              </div>
              <div className="alert-message font-weight-bold">
                {errors.submit}
              </div>
            </Alert>
          )}

          <Form.Group className="mb-3">
            <Form.Label className="mb-0">
              Email<span className="text-danger ms-1">*</span>
            </Form.Label>
            <Form.Control
              name="email"
              placeholder="Enter your email..."
              value={values.email}
              isInvalid={Boolean(touched.email && errors.email)}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={isSubmitting}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Label className="mb-0">
            Password<span className="text-danger ms-1">*</span>
          </Form.Label>
          <InputGroup hasValidation>
            <Form.Control
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="Enter Password..."
              value={values.password}
              onBlur={handleBlur}
              onChange={handleChange}
              isInvalid={Boolean(touched.password && errors.password)}
              disabled={isSubmitting}
            />
            <Button
              variant="outline-secondary"
              onClick={togglePassword}
              disabled={isSubmitting}
            >
              {showPassword ? <EyeOff /> : <Eye />}
            </Button>
            <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>
          </InputGroup>

          <div className="text-center mt-3">
            <Button type="submit" variant="primary" disabled={isSubmitting}>
              Sign In
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SignInForm;
