import { Clipboard } from "lucide-react";
import React, { useContext } from "react";
import { Card } from "react-bootstrap";

import Table from "../../components/table/Table";
import tableColumns from "./tableColumns";

import { ReportsContext } from "./Reports";

const ReportsTable = () => {
  const { isSuper, isAdmin, user, organization, reports, setSelectedReport } =
    useContext(ReportsContext);

  const handleRowClick = async (row) => {
    setSelectedReport(row);
  };

  // Remove Super User Columns unless user is super
  const columns = isSuper
    ? tableColumns
    : tableColumns.filter(
        (col) => col.accessor !== "organization" && col.accessor !== "reportId"
      );

  return (
    <Card>
      <Card.Header className="d-flex justify-content-start">
        <Clipboard className="me-2" size={48} />
        <div>
          <Card.Title>Reports Table</Card.Title>
          <Card.Subtitle>
            {isSuper
              ? "All the inspection reports created by Product Quality Inspection Specialists at Returns Worldwide."
              : isAdmin
              ? `All the inspection reports created by Product Quality Inspection Specialists at Returns Worldwide for ${organization.name}.`
              : `All the inspection reports created by Product Quality Inspection Specialists at Returns Worldwide assigned to ${user.firstName} ${user.lastName}.`}
          </Card.Subtitle>
        </div>
      </Card.Header>
      <Card.Body>
        <Table columns={columns} data={reports} onClickFn={handleRowClick} />
      </Card.Body>
    </Card>
  );
};

export default ReportsTable;
