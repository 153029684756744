import {
  AlertCircle,
  Check,
  Pencil,
  Power,
  PowerOff,
  UserRoundSearch,
  X,
} from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Card, Stack } from "react-bootstrap";
import toast from "react-hot-toast";

import usersService from "../../services/users.service";

import { UsersContext } from "./Users";

const UserDetails = () => {
  const [error, setError] = useState();

  const {
    submitting,
    setSubmitting,
    setIsEdit,
    setUsers,
    selectedUser,
    setSelectedUser,
    setShowUserModal,
    isSuper,
  } = useContext(UsersContext);

  useEffect(() => {
    setError();
  }, [selectedUser]);

  const handleDeactivateUser = async () => {
    setSubmitting(true);

    const result = await usersService.deactivateUser(selectedUser.userId);

    if (result.success) {
      toast.success("User successfully deactivated!");

      setUsers((prevState) => {
        return prevState
          .map((stateUser) => {
            if (stateUser.userId === result.deactivatedUser.userId) {
              return result.deactivatedUser;
            }
            return stateUser;
          })
          .sort((a, b) => {
            if (a.isActive && b.isActive) {
              return 0;
            }
            if (a.isActive) {
              return -1;
            }
            if (b.isActive) {
              return 1;
            }
            return a.isActive - b.isActive;
          });
      });

      setSelectedUser(result.deactivatedUser);
    } else {
      toast.error("Something went wrong. Please try again.");

      setError(result);
    }

    setSubmitting(false);
  };

  const handleReactivateUser = async () => {
    setSubmitting(true);

    const result = await usersService.updateUser(selectedUser.userId, {
      isActive: true,
    });

    if (result?.success) {
      toast.success("User successfully reactivated.");

      setUsers((prevState) => {
        return prevState
          .map((stateUser) => {
            if (stateUser.userId === result.updatedUser.userId) {
              return result.updatedUser;
            }
            return stateUser;
          })
          .sort((a, b) => {
            if (a.isActive && b.isActive) {
              return 0;
            }
            if (a.isActive) {
              return -1;
            }
            if (b.isActive) {
              return 1;
            }
            return a.isActive - b.isActive;
          });
      });

      setSelectedUser(result.updatedUser);
    } else {
      toast.error("Something went wrong. Please try again.");

      setError(result);
    }

    setSubmitting(false);
  };

  // The data to display.
  const {
    firstName,
    lastName,
    email,
    organization,
    isAdmin,
    isActive,
    hasApiAccess,
  } = selectedUser;

  return (
    <>
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-start mb-3">
            <UserRoundSearch className="me-3" size={48} />
            <div>
              <Card.Title>User Details</Card.Title>
              <Card.Subtitle>
                Additional details for{" "}
                <strong>{firstName + " " + lastName}</strong>.
              </Card.Subtitle>
            </div>
          </div>
          <Stack direction="horizontal" gap={3}>
            <Button
              variant="primary"
              onClick={() => {
                setIsEdit(true);
                setShowUserModal(true);
              }}
              disabled={submitting}
            >
              <Pencil className="me-2" size={18} />
              Edit
            </Button>
            <div className="vr" />
            {isActive ? (
              <Button
                variant="danger"
                onClick={() => {
                  handleDeactivateUser();
                  setError();
                }}
                disabled={submitting}
              >
                <PowerOff className="me-2" size={18} />
                Deactivate
              </Button>
            ) : (
              <Button
                variant="warning"
                onClick={() => {
                  handleReactivateUser();
                  setError();
                }}
                disabled={submitting}
              >
                <Power className="me-2" size={18} />
                Reactivate
              </Button>
            )}
          </Stack>
          {error && (
            <Alert variant="danger" className="flex-fill alert-blink mt-3 mb-0">
              <div className="alert-icon">
                <AlertCircle
                  className="position-relative top-50 start-50 translate-middle"
                  size={20}
                />
              </div>
              <div className="alert-message font-weight-bold">
                {error.message}
              </div>
            </Alert>
          )}
        </Card.Header>
        <Card.Body>
          <div className="mb-3">
            <span className="d-block mb-0">First Name</span>
            <h4>{firstName}</h4>
          </div>

          <div className="mb-3">
            <span className="d-block mb-0">Last Name</span>
            <h4>{lastName}</h4>
          </div>

          <div className="mb-3">
            <span className="d-block mb-0">Email</span>
            <h4>{email}</h4>
          </div>

          {isSuper && (
            <div className="mb-3">
              <span className="d-block mb-0">Organization</span>
              <h4>{organization}</h4>
            </div>
          )}

          <div className="mb-3">
            <span className="d-block mb-0">Administrator</span>
            {isAdmin ? (
              <Check color="#4BBF73" size={24} />
            ) : (
              <X color="#d9534f" size={24} />
            )}
          </div>

          <div className="mb-3">
            <span className="d-block mb-0">Active</span>
            {isActive ? (
              <Check color="#4BBF73" size={24} />
            ) : (
              <X color="#d9534f" size={24} />
            )}
          </div>

          {isSuper && (
            <>
              <div className="mb-3">
                <span className="d-block mb-0">API Access</span>
                {hasApiAccess ? (
                  <Check color="#4BBF73" size={24} />
                ) : (
                  <X color="#d9534f" size={24} />
                )}
              </div>
              <div className="mb-3">
                <span className="d-block mb-0">Super</span>
                {selectedUser.isSuper ? (
                  <Check color="#4BBF73" size={24} />
                ) : (
                  <X color="#d9534f" size={24} />
                )}
              </div>
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default UserDetails;
