import { ClipboardList, Frown, Meh, Navigation, Smile } from "lucide-react";
import React, { useContext } from "react";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import CopyButton from "../../components/CopyButton";

import { ClaimsContext } from "./Claims";

const ReportDetails = () => {
  const navigate = useNavigate();

  const { selectedClaim } = useContext(ClaimsContext);

  // The data to display.
  const {
    reportId,
    lpn,
    fnsku,
    removalOrderId,
    reason,
    subReason,
    reasonNote,
    reporter,
    vendor,
    organization,
    assignedUser,
    statusId,
    status,
    createdAt,
    updatedAt,
  } = selectedClaim.report;

  const handleGoToReport = async () => {
    navigate(`/reports/${reportId}`);
  };

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <div className="d-flex justify-content-start">
            <ClipboardList className="me-2" size={48} />
            <div>
              <Card.Title>Inspection Report Details</Card.Title>
              <Card.Subtitle>
                Additional details about the selected claim's inspection report.
              </Card.Subtitle>
            </div>
          </div>
          <div className="d-flex justify-content-end mb-3">
            <Button
              className="ms-3"
              variant="primary"
              onClick={() => {
                handleGoToReport();
              }}
            >
              <Navigation className="me-2" size={18} />
              Go To Report
            </Button>
          </div>
        </Card.Header>
        <Card.Body className="pt-0">
          <hr className="mt-0" />
          <Row className="gy-3" xs={2} md={3}>
            <Col>
              <Form.Label className="mb-0">LPN</Form.Label>
              <InputGroup>
                <div className="fw-bold form-control text-nowrap overflow-hidden">
                  {lpn}
                </div>
                <CopyButton text={lpn} variant="outline-secondary" />
              </InputGroup>
            </Col>
            <Col>
              <Form.Label className="mb-0">FNSKU</Form.Label>
              <InputGroup>
                <div className="fw-bold form-control text-nowrap overflow-hidden">
                  {fnsku ? fnsku : "TBD"}
                </div>
                {fnsku && (
                  <CopyButton text={fnsku} variant="outline-secondary" />
                )}
              </InputGroup>
            </Col>
            <Col>
              <Form.Label className="mb-0">Removal Order ID</Form.Label>
              <InputGroup>
                <div className="fw-bold form-control text-nowrap overflow-hidden">
                  {removalOrderId ? removalOrderId : "TBD"}
                </div>
                {removalOrderId && (
                  <CopyButton
                    text={removalOrderId}
                    variant="outline-secondary"
                  />
                )}
              </InputGroup>
            </Col>
            <Col>
              <Form.Label className="mb-0">Reason</Form.Label>
              <div className="fw-bold form-control">{reason}</div>
            </Col>
            <Col>
              <Form.Label className="mb-0">Sub Reason</Form.Label>
              <div className="fw-bold form-control">{subReason}</div>
            </Col>
            <Col>
              <Form.Label className="mb-0">Reason Note</Form.Label>
              <div className="fw-bold form-control">
                {reasonNote ? reasonNote : "None"}
              </div>
            </Col>
            <Col>
              <Form.Label className="mb-0">Vendor</Form.Label>
              <div className="fw-bold form-control">{vendor}</div>
            </Col>
            <Col>
              <Form.Label className="mb-0">Reporter</Form.Label>
              <div className="fw-bold form-control">{reporter}</div>
            </Col>
            <Col>
              <Form.Label className="mb-0">Organization</Form.Label>
              <div className="fw-bold form-control">{organization}</div>
            </Col>
            <Col>
              <Form.Label className="mb-0">Assigned To</Form.Label>
              <div className="fw-bold form-control">{assignedUser}</div>
            </Col>
            <Col>
              <Form.Label className="mb-0">Created At</Form.Label>
              <div className="fw-bold form-control">
                {new Date(createdAt).toLocaleString("en-US")}
              </div>
            </Col>
            <Col>
              <Form.Label className="mb-0">Updated At</Form.Label>
              <div className="fw-bold form-control">
                {new Date(updatedAt).toLocaleString("en-US")}
              </div>
            </Col>
            <Col>
              <Form.Label className="mb-0">Status</Form.Label>
              <InputGroup className="mb-3" style={{ zIndex: 1030 }}>
                <div className="fw-bold form-control text-nowrap overflow-hidden">
                  {status}
                </div>
                <Button
                  variant={
                    statusId === 1
                      ? "outline-warning"
                      : statusId === 2
                      ? "outline-success"
                      : "outline-danger"
                  }
                  disabled={true}
                >
                  {statusId === 1 ? (
                    <Meh size={18} />
                  ) : statusId === 2 ? (
                    <Smile size={18} />
                  ) : (
                    <Frown size={18} />
                  )}
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default ReportDetails;
