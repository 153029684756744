import { Formik } from "formik";
import { Form, Row, Col, Alert, Fade, Stack, Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { AlertOctagon, Smile } from "react-feather";
import { useState } from "react";
import * as Yup from "yup";

import useAuth from "../../hooks/useAuth";
import organizationsService from "../../services/organizations.service";

const OrganizationSettings = ({ formikRef }) => {
  const { organization, updateOrganization } = useAuth();

  //Global Account Settings Status alert
  const [status, setStatus] = useState();

  return (
    <>
      <h3>Organization Settings</h3>
      <p className="text-muted">Change your basic organization settings.</p>
      <Row>
        <Col xl={6}>
          {status?.success && (
            <Alert variant="success" className="flex-fill alert-blink">
              <div className="alert-icon">
                <Smile
                  className="position-relative top-50 start-50 translate-middle"
                  size={20}
                />
              </div>
              <div className="alert-message font-weight-bold">
                {status.message}
              </div>
            </Alert>
          )}
          {status?.success === false && (
            <Alert variant="danger" className="flex-fill alert-blink">
              <div className="alert-icon">
                <AlertOctagon
                  className="position-relative top-50 start-50 translate-middle"
                  size={20}
                />
              </div>
              <div className="alert-message font-weight-bold">
                {status.message}
              </div>
            </Alert>
          )}
          <hr />
          <Formik
            innerRef={formikRef}
            enableReinitialize
            initialValues={{
              logo: "",
              name: organization?.name || "",
              phoneNumber: organization?.phoneNumber || "",
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string()
                .max(50, "Name must be less than 50 characters long.")
                .required("Name is required."),
              phoneNumber: Yup.string()
                .max(50, "Phone Number must be less than 50 characters long.")
                .required("Phone Number is required."),
            })}
            onSubmit={async (values, { setErrors }) => {
              console.log(values);

              const response = await organizationsService.updateOrganization(
                organization.organizationId,
                values
              );

              console.log(response);

              if (response.success) {
                toast.success("Your organization settings have been updated!");
                setStatus({
                  success: true,
                  message: "Your organization settings have been updated!",
                });

                updateOrganization(response.updatedOrganization);
              } else {
                toast.error("Uh oh, something went wrong!");
                setStatus({ success: false });
                setErrors({ submit: response.message });
              }
            }}
          >
            {({
              handleBlur,
              handleChange,
              handleReset,
              handleSubmit,
              setFieldValue,
              dirty,
              errors,
              isSubmitting,
              touched,
              values,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Logo</Form.Label>
                  <Form.Control
                    id="logo"
                    type="file"
                    name="logo"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("logo", e.currentTarget.files[0]);
                    }}
                    isInvalid={Boolean(touched.logo && errors.logo)}
                    accept="image/png, image/jpeg"
                    disabled={isSubmitting}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.logo}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">
                    Name<span className="text-danger ms-1">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Enter Name..."
                    value={values.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isInvalid={Boolean(touched.name && errors.name)}
                    disabled={isSubmitting}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label className="m-0">
                    Phone Number<span className="text-danger ms-1">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="phoneNumber"
                    placeholder="Enter Phone Number..."
                    value={values.phoneNumber}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isInvalid={Boolean(
                      touched.phoneNumber && errors.phoneNumber
                    )}
                    disabled={isSubmitting}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.phoneNumber}
                  </Form.Control.Feedback>
                </Form.Group>

                <Fade in={dirty}>
                  <Stack direction="horizontal" gap={3}>
                    <Button
                      type="submit"
                      variant="outline-primary"
                      disabled={isSubmitting || !dirty}
                    >
                      Save Changes
                    </Button>
                    <div className="vr" />
                    <Button
                      variant="outline-danger"
                      onClick={() => {
                        handleReset();
                      }}
                      disabled={isSubmitting || !dirty}
                    >
                      Reset
                    </Button>
                  </Stack>
                </Fade>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </>
  );
};

export default OrganizationSettings;
