import JSZip from "jszip";
import { Download, Images } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Carousel,
  Col,
  Image,
  Modal,
  Row,
  Fade,
} from "react-bootstrap";

import Loader from "../../components/Loader";

import reportsService from "../../services/reports.service";

import { ReportsContext } from "./Reports";

const ReportImages = () => {
  const [loading, setLoading] = useState(false);

  const [showImageCarousel, setShowImageCarousel] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);

  const { setReports, submitting, selectedReport } = useContext(ReportsContext);

  useEffect(() => {
    if (!selectedReport.reportImages) {
      getReportImages();
    }
  }, [selectedReport]);

  const getReportImages = async () => {
    setLoading(true);

    const response = await reportsService.getReportImages(
      selectedReport.reportId
    );

    if (response.success) {
      if (response.reportImages.length > 0) {
        setReports((prevState) => {
          return prevState.map((stateReport) => {
            if (stateReport.reportId === response.reportImages[0].reportId) {
              stateReport.reportImages = response.reportImages;
            }
            return stateReport;
          });
        });
        selectedReport.reportImages = response.reportImages;
      }
    } else {
      console.log(response);
    }

    setLoading(false);
  };

  const downloadAllImages = async () => {
    const zip = new JSZip();

    // Add each base64 image to the ZIP archive
    selectedReport.reportImages.forEach((image) => {
      const blob = dataURItoBlob(image.imageSrc);
      zip.file(`${image.imageId}.jpg`, blob);
    });

    // Generate the ZIP blob and create a download link
    const zipBlob = await zip.generateAsync({ type: "blob" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(zipBlob);
    link.download = `${selectedReport.lpn}-claim-images.zip`;
    link.click();
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = window.atob(dataURI.split(",")[1]);
    const mimeType = dataURI.split(",")[0].split(":")[1].split(";")[0];

    const buffer = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      buffer[i] = byteString.charCodeAt(i);
    }

    return new Blob([buffer], { type: mimeType });
  };

  return (
    <>
      {selectedReport.reportImages && (
        <Modal
          show={showImageCarousel}
          onHide={() => setShowImageCarousel(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Report Images</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Carousel
              indicators={true}
              controls={true}
              interval={null}
              activeIndex={carouselIndex}
              onSelect={(selectedIndex) => setCarouselIndex(selectedIndex)}
            >
              {selectedReport.reportImages.map((image, index) => {
                return (
                  <Carousel.Item key={index}>
                    <Image src={`${image.imageSrc}`} fluid />
                    <Carousel.Caption>
                      <h3 className="text-white">{image.imageId}</h3>
                    </Carousel.Caption>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </Modal.Body>
        </Modal>
      )}
      <Card className="sticky-top" style={{ top: 20 }}>
        <Card.Header className="border-bottom">
          <div className="d-flex justify-content-start mb-3">
            <Images className="me-2" size={48} />
            <div>
              <Card.Title>Report Images</Card.Title>
              <Card.Subtitle>
                Images associated with the selected report.
              </Card.Subtitle>
            </div>
          </div>
          <div className="d-flex">
            <Button
              className="flex-fill"
              variant="primary"
              onClick={() => downloadAllImages()}
              disabled={submitting}
            >
              <Download className="me-2" size={18} />
              Download Images
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          {!selectedReport.reportImages && loading && (
            <Fade in={loading}>
              <div>
                <Loader />
              </div>
            </Fade>
          )}
          <Fade in={Boolean(selectedReport.reportImages)}>
            <div>
              {selectedReport.reportImages && (
                <Row>
                  {selectedReport.reportImages.map((image, index) => {
                    return (
                      <Col key={index} xs={6} className="mb-3">
                        <Image
                          src={`${image.imageSrc}`}
                          fluid
                          onClick={() => {
                            setShowImageCarousel(true);
                            setCarouselIndex(index);
                          }}
                          className="cursor-pointer border"
                        />
                      </Col>
                    );
                  })}
                </Row>
              )}
            </div>
          </Fade>
        </Card.Body>
      </Card>
    </>
  );
};

export default ReportImages;
