import { DropdownFilter } from "../../components/table/filters";

const tableColumns = [
  {
    Header: "Report ID",
    accessor: "reportId",
  },
  {
    Header: "LPN",
    accessor: "lpn",
  },
  {
    Header: "FNSKU",
    accessor: "fnsku",
  },
  {
    Header: "Removal Order ID",
    accessor: "removalOrderId",
  },
  {
    Header: "Reason",
    accessor: "reason",
  },
  {
    Header: "Sub Reason",
    accessor: "subReason",
  },
  {
    Header: "Vendor",
    accessor: "vendor",
  },
  {
    Header: "Organization",
    accessor: "organization",
  },
  {
    Header: "Assigned To",
    accessor: "assignedUser",
    Filter: DropdownFilter,
  },
  {
    Header: "Status",
    accessor: "status",
    Filter: DropdownFilter,
  },
  {
    Header: "Created At",
    accessor: "createdAt",
    Cell: ({ value }) => {
      return <span>{new Date(value).toLocaleString()}</span>;
    },
  },
  {
    Header: "Updated At",
    accessor: "updatedAt",
    Cell: ({ value }) => {
      return <span>{new Date(value).toLocaleString()}</span>;
    },
  },
];

export default tableColumns;
