import { AlertOctagon, Smile, AlertCircle, X } from "lucide-react";
import React, { useContext, useState } from "react";
import { Alert, Card, Modal, Collapse, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";

import UserForm from "./UserForm";

import { UsersContext } from "./Users";

const UserModal = ({ isEdit }) => {
  const [status, setStatus] = useState();
  const [showNote, setShowNote] = useState(true);

  const { showUserModal, setShowUserModal } = useContext(UsersContext);

  return (
    <Modal
      show={showUserModal}
      onHide={() => setShowUserModal(false)}
      backdrop="static"
      keyboard={false}
      size="sm"
    >
      {isEdit ? (
        <Modal.Header className="d-flex flex-column align-items-start">
          <Card.Title>Edit User</Card.Title>
          <Card.Subtitle className="text-muted">
            Change the name, email, and special permissions for the user.
          </Card.Subtitle>
        </Modal.Header>
      ) : (
        <Modal.Header className="d-flex flex-column align-items-start">
          <Card.Title>Create User</Card.Title>
          <Card.Subtitle className="text-muted">
            Select an organization, enter a name, email, temporary password, and
            set special permissions for the new user.
          </Card.Subtitle>

          <Collapse in={showNote}>
            <div>
              <Alert
                variant="warning"
                className="flex-fill alert-blink mt-3 mb-0"
              >
                <div className="alert-icon">
                  <AlertCircle
                    className="position-relative top-50 start-50 translate-middle"
                    size={20}
                  />
                </div>
                <div className="alert-message">
                  <strong>Note:</strong> Organization Admins should invite their
                  users.
                </div>
                <Button
                  variant="light"
                  style={{ backgroundColor: "transparent" }}
                  size="sm"
                  onClick={() => setShowNote(false)}
                >
                  <X size={18} />
                </Button>
              </Alert>
            </div>
          </Collapse>
        </Modal.Header>
      )}
      <Modal.Body>
        {status && (
          <Alert
            variant={status?.success ? "success" : "danger"}
            className="flex-fill alert-blink"
          >
            <div className="alert-icon">
              {status?.success ? (
                <Smile
                  className="position-relative top-50 start-50 translate-middle"
                  size={20}
                />
              ) : (
                <AlertOctagon
                  className="position-relative top-50 start-50 translate-middle"
                  size={20}
                />
              )}
            </div>
            <div className="alert-message font-weight-bold">
              {status.message}
            </div>
          </Alert>
        )}
        <UserForm isEdit={isEdit} setStatus={setStatus} />
      </Modal.Body>
    </Modal>
  );
};

export default UserModal;
