import axios from "axios";
import { handleError } from "../utils/helper";

const HOST_URL = process.env.REACT_APP_HOST_URL;

const getSession = async () => {
  try {
    return (await axios.get(`${HOST_URL}/auth/sessions`)).data;
  } catch (error) {
    return handleError(error);
  }
};

const createSession = async (email, password) => {
  try {
    return (
      await axios.post(`${HOST_URL}/auth/sessions`, {
        email,
        password,
      })
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

const invalidateSession = async (refreshToken) => {
  try {
    return await axios.patch(`${HOST_URL}/auth/sessions`, {
      refreshToken,
    });
  } catch (error) {
    return handleError(error);
  }
};

const sendInvite = async (email) => {
  try {
    return (
      await axios.post(`${HOST_URL}/auth/invite`, {
        email,
      })
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

const signUp = async (token, newUserData) => {
  try {
    return (
      await axios.post(`${HOST_URL}/auth/signUp`, {
        token,
        newUserData,
      })
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Sends an OTP (One-Time Password) to the specified email address for password reset.
 *
 * @async
 * @function sendOtp
 * @param {string} email - The email address to which the OTP should be sent.
 * @returns {Promise<Object>} The response data from the server.
 * @throws {Error} If the request fails, the error is handled by handleError.
 */
const sendOtp = async (email) => {
  try {
    return (
      await axios.post(`${HOST_URL}/auth/resetPassword/send-otp`, {
        email,
      })
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Verifies the OTP (One-Time Password) using the provided token and OTP code.
 *
 * @async
 * @function verifyOtp
 * @param {string} token - The token associated with the OTP.
 * @param {string} otpCode - The OTP code to verify.
 * @returns {Promise<Object>} The response data from the server.
 * @throws {Error} If the request fails, the error is handled by handleError.
 */
const verifyOtp = async (token, otpCode) => {
  try {
    return (
      await axios.post(`${HOST_URL}/auth/resetPassword/verify-otp`, {
        token,
        otpCode,
      })
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Resets the password using the provided token and new password.
 *
 * @async
 * @function resetPassword
 * @param {string} token - The token associated with the password reset request.
 * @param {string} password - The new password to set.
 * @returns {Promise<Object>} The response data from the server.
 * @throws {Error} If the request fails, the error is handled by handleError.
 */
const resetPassword = async (token, password) => {
  try {
    return (
      await axios.post(`${HOST_URL}/auth/resetPassword/reset-password`, {
        token,
        password,
      })
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

const services = {
  getSession,
  createSession,
  invalidateSession,
  signUp,
  sendInvite,
  sendOtp,
  verifyOtp,
  resetPassword,
};

export default services;
