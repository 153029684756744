import React from "react";
import { Card, Image } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { User } from "react-feather";
import { useNavigate } from "react-router-dom";

import logo from "../../assets/img/claims_pro_logo_color.svg";
import SignInForm from "../../components/auth/SignIn";

const SignInPage = () => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Helmet title="Sign In" />
      <div className="d-flex flex-column">
        <Image src={logo} alt="ClaimsPro logo" className="img-fluid" />
        <p className="lead mt-1 align-self-end">by Returns Worldwide</p>
      </div>

      <Card>
        <Card.Body>
          <div className="text-center m-sm-4">
            <h2>Welcome</h2>
            <p className="lead">Sign in to your account to continue.</p>
          </div>
          <div className="m-sm-4">
            <SignInForm />
            <div className="text-center mt-4">
              <Card.Link
                onClick={() => {
                  navigate("/auth/reset-password");
                }}
              >
                Forgot your password?
              </Card.Link>
            </div>
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default SignInPage;
