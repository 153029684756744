import { MailPlus, UserPlus2, UsersRound } from "lucide-react";
import React, { useContext, useState } from "react";
import { Button, Card } from "react-bootstrap";

import Table from "../../components/table/Table";
import InviteUserModal from "./InviteUserModal";
import tableColumns from "./tableColumns";

import { UsersContext } from "./Users";

const UsersTable = () => {
  const {
    isSuper,
    submitting,
    setIsEdit,
    users,
    setSelectedUser,
    setShowUserModal,
  } = useContext(UsersContext);

  const [showInviteUserModal, setShowInviteUserModal] = useState(false);

  const handleRowClick = async (row) => {
    setSelectedUser(row);
  };

  // Remove Super User Columns unless user is super
  const columns = isSuper
    ? tableColumns
    : tableColumns.filter(
        (col) =>
          col.accessor !== "isSuper" &&
          col.accessor !== "hasApiAccess" &&
          col.accessor !== "organization"
      );

  return (
    <>
      <InviteUserModal
        showInviteUserModal={showInviteUserModal}
        setShowInviteUserModal={setShowInviteUserModal}
      />
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <div className="d-flex justify-content-start">
            <UsersRound size={48} className="me-3" />
            <div>
              <Card.Title>Users Table</Card.Title>
              <Card.Subtitle>
                Click on a user to view or edit their details.
              </Card.Subtitle>
            </div>
          </div>
          <div>
            <Button
              variant="primary"
              onClick={() => {
                setSelectedUser();
                setShowInviteUserModal(true);
              }}
              disabled={submitting}
            >
              <MailPlus size={18} className="me-2" />
              Invite User
            </Button>
            {isSuper && (
              <Button
                className="ms-3"
                variant="primary"
                onClick={() => {
                  setSelectedUser();
                  setIsEdit(false);
                  setShowUserModal(true);
                }}
                disabled={submitting}
              >
                <UserPlus2 size={18} className="me-2" />
                Create User
              </Button>
            )}
          </div>
        </Card.Header>
        <Card.Body>
          <Table columns={columns} data={users} onClickFn={handleRowClick} />
        </Card.Body>
      </Card>
    </>
  );
};

export default UsersTable;
