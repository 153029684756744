import React, { createContext, useEffect, useState } from "react";
import { Col, Container, Fade, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import Loader from "../../components/Loader";
import UserDetails from "./UserDetails";
import UsersTable from "./UsersTable";
import UserModal from "./UserModal";

import organizationsService from "../../services/organizations.service";
import usersService from "../../services/users.service";

import useAuth from "../../hooks/useAuth";

const UsersContext = createContext();

const Users = () => {
  const { user, organization } = useAuth();
  const isSuper = user?.isSuper;

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [users, setUsers] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [organizationOptions, setOrganizationOptions] = useState();

  useEffect(() => {
    if (user) {
      fetchData();
    }
  }, [user]);

  const fetchData = async () => {
    setLoading(true);
    if (user.isSuper) {
      await Promise.all([getUsers(), getOrganizationOptions()]);
    } else {
      await getUsers();
    }
    setLoading(false);
  };

  const getUsers = async () => {
    const result = await usersService.getUsers();

    if (result?.success) {
      const sortedUsers = result.users.sort((a, b) => {
        if (a.isActive && b.isActive) {
          return 0;
        }
        if (a.isActive) {
          return -1;
        }
        if (b.isActive) {
          return 1;
        }
        return a.isActive - b.isActive;
      });
      setUsers(sortedUsers);
    }
  };

  const getOrganizationOptions = async () => {
    const result = await organizationsService.getOrganizations();

    if (result?.success) {
      const options = result.organizations.map((org) => ({
        value: org.organizationId,
        label: org.name,
      }));
      setOrganizationOptions(options);
    }
  };

  return (
    <UsersContext.Provider
      value={{
        isSuper,
        user,
        organization,
        loading,
        submitting,
        setSubmitting,
        showUserModal,
        setShowUserModal,
        setIsEdit,
        users,
        setUsers,
        selectedUser,
        setSelectedUser,
        organizationOptions,
      }}
    >
      <Helmet title="Users" />
      <Container fluid className="p-0">
        <h1 className="mb-3">Users</h1>

        <Row>
          <Col xl={8}>
            {!users && loading && (
              <Fade in={loading}>
                <div>
                  <Loader />
                </div>
              </Fade>
            )}
            <Fade in={Boolean(users)}>
              <div>
                {users && (
                  <>
                    <UserModal isEdit={isEdit} />
                    <UsersTable
                      users={users}
                      setSelectedUser={setSelectedUser}
                    />
                  </>
                )}
              </div>
            </Fade>
          </Col>
          <Col xl={4}>
            <Fade in={Boolean(selectedUser)}>
              <div>{selectedUser && <UserDetails />}</div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </UsersContext.Provider>
  );
};

export { UsersContext };

export default Users;
