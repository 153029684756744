import React, { useState, useEffect } from "react";
import { Card, Image } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { User } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";

import logo from "../../assets/img/claims_pro_logo_color.svg";
import VerifyOtp from "../../components/auth/VerifyOtp";
import SendOtp from "../../components/auth/SendOtp";
import SetPassword from "../../components/auth/SetPassword";
import { isValidToken } from "../../utils/jwt";

const ResetPasswordPage = () => {
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();

  // Email param exists when coming from an email link.
  const { token } = useParams();

  useEffect(() => {
    if (token) {
      if (!isValidToken(token)) {
        navigate("/auth/400");
      }
    }
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Reset Password" />
      <div className="d-flex flex-column">
        <Image src={logo} alt="ClaimsPro logo" className="img-fluid" />
        <p className="lead mt-1 align-self-end">by Returns Worldwide</p>
      </div>

      <Card>
        <Card.Body>
          <div className="m-sm-4">
            {token && validated ? (
              <SetPassword token={token} />
            ) : token ? (
              <VerifyOtp token={token} setValidated={setValidated} />
            ) : (
              <SendOtp />
            )}
            <div className="text-center mt-4">
              <Card.Link
                onClick={() => {
                  navigate("/auth/sign-in");
                }}
              >
                Go back to sign in
              </Card.Link>
            </div>
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default ResetPasswordPage;
