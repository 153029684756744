import React from "react";
import { Container } from "react-bootstrap";

const Footer = () => (
  <footer className="footer">
    <Container fluid className="text-center">
      <p className="mb-0">
        &copy; {new Date().getFullYear()} -{" "}
        <span href="/" className="text-muted">
          Returns Worldwide
        </span>
      </p>
    </Container>
  </footer>
);

export default Footer;
