import {
  Briefcase,
  Clipboard,
  Globe,
  Settings,
  UsersRound,
} from "lucide-react";

const navSection = [
  {
    href: "/reports",
    icon: Clipboard,
    title: "Reports",
  },
  {
    href: "/claims",
    icon: Briefcase,
    title: "Claims",
  },
];

const adminSection = [
  {
    href: "/users",
    icon: UsersRound,
    title: "Users",
  },
];

const superSection = [
  {
    href: "/organizations",
    icon: Globe,
    title: "Organizations",
  },
];

const settingsSection = [
  {
    href: "/settings",
    icon: Settings,
    title: "Settings",
  },
];

const navItems = [
  {
    title: "Navigation",
    pages: navSection,
  },
  {
    title: "Administrator",
    pages: adminSection,
  },
  {
    title: "Super Administrator",
    pages: superSection,
  },
  {
    title: "Settings",
    pages: settingsSection,
  },
];

export default navItems;
