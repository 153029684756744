import { X, Check } from "react-feather";
import { Image } from "react-bootstrap";
import { faImage } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const tableColumns = [
  {
    Header: "Logo",
    accessor: "logo",
    Cell: ({ value }) => {
      if (value) {
        return (
          <Image
            className="img-thumbnail"
            style={{ maxHeight: "75px" }}
            src={value}
            onError={({ currentTarget, error }) => {
              currentTarget.onerror = null; // prevents looping
              // currentTarget.src = imageNotFound;
              console.log(error);
            }}
            alt="Organization Logo"
          />
        );
      } else {
        return <FontAwesomeIcon icon={faImage} />;
      }
    },
    filterable: false,
    searchable: false,
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Phone Number",
    accessor: "phoneNumber",
  },
  {
    Header: "Active",
    accessor: "isActive",
    Cell: ({ value }) => {
      if (value) {
        return <Check color="#4BBF73" size={24} />;
      } else {
        return <X color="#d9534f" size={24} />;
      }
    },
    sortType: (row) => (row.original.isActive ? -1 : 1),
  },
];

export default tableColumns;
