export const handleError = (error) => {
  const errorObject = {
    message: "An error occurred.",
    data: null,
    status: null,
    headers: null,
  };

  if (error.config) {
    // console.log("Request Configuration:", error.config);
  }

  if (error.response) {
    // The request was made and the server responded with a status code that falls out of the range of 2xx
    // console.log("Response Data:", error.response.data);
    // console.log("Response Status:", error.response.status);
    // console.log("Response Headers:", error.response.headers);

    errorObject.message = error.response.data.message;
    errorObject.data = error.response.data;
    errorObject.status = error.response.status;
    errorObject.headers = error.response.headers;
  } else if (error.request) {
    // The request was made but no response was received
    // console.log("Request:", error.request);

    errorObject.message = "No response received.";
    errorObject.request = error.request;
  } else {
    // Something happened in setting up the request that triggered an Error
    // console.log("Error:", error.message);

    errorObject.message = error.message;
  }

  return errorObject;
};

/**
 * Builds a query string for a URL based on the provided query parameters.
 * @param {Object} params - The query parameters object.
 * @returns {string} The generated query string.
 */
export const buildQueryString = (params) => {
  const query = Object.entries(params)
    .filter((param) => param[1] && param)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");

  return query ? `?${query}` : "";
};

export const handleScroll = (elementId) => {
  const element = document.getElementById(elementId);

  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }
};

export const testDelay = (ms) =>
  new Promise((resolve) => setTimeout(resolve, ms));
