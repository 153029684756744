import { Copy, Dna, FileText, Frown, Meh, Pencil, Smile } from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Collapse,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";

import CopyButton from "../../components/CopyButton";
import ClaimModal from "./ClaimModal";

import claimsService from "../../services/claims.service";
import reportsService from "../../services/reports.service";

import { getCaseBody } from "./caseBodies";

import { ClaimsContext } from "./Claims";

const ClaimDetails = () => {
  const [showClaimModal, setShowClaimModal] = useState(false);
  const [template, setTemplate] = useState("");

  const {
    setClaims,
    submitting,
    setSubmitting,
    selectedClaim,
    setSelectedClaim,
    claimStatusOptions,
  } = useContext(ClaimsContext);

  const {
    claimId,
    user: claimUser,
    caseId,
    reimbursementId,
    amount,
    notes,
    organization,
    statusId,
    status,
    createdAt,
    updatedAt,
  } = selectedClaim;

  useEffect(() => {
    if (!selectedClaim.report) {
      getReport();
    }
    setTemplate();
  }, [selectedClaim]);

  const getReport = async () => {
    const response = await reportsService.getReports({
      claimId: selectedClaim.claimId,
    });

    if (response.success) {
      const report = response.reports[0];

      setClaims((prevState) => {
        return prevState.map((stateClaim) => {
          if (stateClaim.reportId === report.reportId) {
            stateClaim.report = report;
          }
          return stateClaim;
        });
      });
      setSelectedClaim((prevState) => {
        prevState.report = report;
        return prevState;
      });
    } else {
      console.log(response);
      toast.error(
        "Something went wrong getting the associated inspection report."
      );
    }
  };

  const handleChangeStatus = async (newStatusId) => {
    setSubmitting(true);

    // Reason for all params is that updateClaim is semi post semi patch.
    const response = await claimsService.updateClaim(claimId, {
      caseId,
      reimbursementId,
      amount,
      notes,
      statusId: newStatusId,
    });

    if (response.success) {
      setClaims((prevState) => {
        return prevState.map((stateClaim) => {
          if (stateClaim.claimId === response.updatedClaim.claimId) {
            stateClaim = response.updatedClaim;
          }
          return stateClaim;
        });
      });
      setSelectedClaim((prevState) => {
        return { ...prevState, ...response.updatedClaim };
      });
      toast.success("Successfully changed the claim status!");
      setSubmitting(false);
    } else {
      console.log(response);
      toast.error("Something went wrong changing the claim status.");
      setSubmitting(false);
    }
  };

  const generateTemplate = () => {
    const templateString = getCaseBody(
      selectedClaim.report.subReasonId,
      selectedClaim.report.removalOrderId,
      selectedClaim.report.lpn,
      selectedClaim.report.fnsku
    );

    setTemplate(templateString);
  };

  return (
    <>
      <ClaimModal
        showClaimModal={showClaimModal}
        setShowClaimModal={setShowClaimModal}
      />
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <div className="d-flex justify-content-start">
            <FileText className="me-2" size={48} />
            <div>
              <Card.Title>Claim Details</Card.Title>
              <Card.Subtitle>
                Additional details about the selected claim.
              </Card.Subtitle>
            </div>
          </div>
          <div className="d-flex justify-content-end mb-3">
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip>
                  Generates a case body based on the inspection report sub
                  reason.
                </Tooltip>
              }
            >
              <Button
                variant="primary"
                onClick={() => {
                  generateTemplate();
                }}
                disabled={submitting || !selectedClaim.report}
              >
                <Dna className="me-2" size={18} />
                Template
              </Button>
            </OverlayTrigger>
            <Button
              className="ms-3"
              variant="primary"
              onClick={() => {
                setShowClaimModal(true);
              }}
              disabled={submitting}
            >
              <Pencil className="me-2" size={18} />
              Edit
            </Button>
          </div>
        </Card.Header>
        <Card.Body className="pt-0">
          <hr className="mt-0" />
          <Row className="gy-3" xs={2} md={3}>
            <Col>
              <Form.Label className="mb-0">Case ID</Form.Label>
              <InputGroup>
                <div className="fw-bold form-control text-nowrap overflow-hidden">
                  {caseId ? caseId : "TBD"}
                </div>
                <CopyButton text={caseId} variant="outline-secondary" />
              </InputGroup>
            </Col>
            <Col>
              <Form.Label className="mb-0">Reimbursement ID</Form.Label>
              <InputGroup>
                <div className="fw-bold form-control text-nowrap overflow-hidden">
                  {reimbursementId ? reimbursementId : "TBD"}
                </div>
                {reimbursementId && (
                  <CopyButton
                    text={reimbursementId}
                    variant="outline-secondary"
                  />
                )}
              </InputGroup>
            </Col>
            <Col>
              <Form.Label className="mb-0">Amount</Form.Label>
              <InputGroup>
                <InputGroup.Text>$</InputGroup.Text>
                <div className="fw-bold form-control text-nowrap overflow-hidden">
                  {Number(amount).toFixed(2)}
                </div>
              </InputGroup>
            </Col>
            <Col>
              <Form.Label className="mb-0">Notes</Form.Label>
              <div className="fw-bold form-control">
                {notes ? notes : "None"}
              </div>
            </Col>
            <Col>
              <Form.Label className="mb-0">Organization</Form.Label>
              <div className="fw-bold form-control">{organization}</div>
            </Col>
            <Col>
              <Form.Label className="mb-0">Creator</Form.Label>
              <div className="fw-bold form-control">{claimUser}</div>
            </Col>
            <Col>
              <Form.Label className="mb-0">Created At</Form.Label>
              <div className="fw-bold form-control">
                {new Date(createdAt).toLocaleString("en-US")}
              </div>
            </Col>
            <Col>
              <Form.Label className="mb-0">Updated At</Form.Label>
              <div className="fw-bold form-control">
                {new Date(updatedAt).toLocaleString("en-US")}
              </div>
            </Col>
            <Col>
              <Form.Label className="mb-0">Status</Form.Label>
              <InputGroup className="mb-3" style={{ zIndex: 1030 }}>
                <div className="fw-bold form-control text-nowrap overflow-hidden">
                  {status}
                </div>
                <DropdownButton
                  variant={
                    statusId === 1 || statusId === 2
                      ? "outline-warning"
                      : statusId === 3
                      ? "outline-success"
                      : "outline-danger"
                  }
                  title={
                    submitting ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : statusId === 1 ? (
                      <Meh size={18} />
                    ) : statusId === 2 ? (
                      <Smile size={18} />
                    ) : statusId === 3 ? (
                      <Smile size={18} />
                    ) : (
                      <Frown size={18} />
                    )
                  }
                  align="end"
                  disabled={submitting}
                >
                  <Dropdown.Header>Change Status To</Dropdown.Header>
                  {claimStatusOptions &&
                    claimStatusOptions
                      .filter((option) => option.value !== statusId)
                      .map((option, index) => {
                        return (
                          <Dropdown.Item
                            key={index}
                            onClick={() => {
                              handleChangeStatus(option.value);
                            }}
                          >
                            {option.label}
                          </Dropdown.Item>
                        );
                      })}
                </DropdownButton>
              </InputGroup>
            </Col>
          </Row>
          <Collapse in={Boolean(template)}>
            <div>
              <hr></hr>
              <div className="d-flex justify-content-between mb-3">
                <h4>Template</h4>
                <CopyToClipboard
                  text={template}
                  onCopy={() => {
                    toast.success("Template successfully copied to clipboard!");
                  }}
                >
                  <Button variant="outline-secondary">
                    <Copy className="me-2" size={18} />
                    Copy Template
                  </Button>
                </CopyToClipboard>
              </div>
              <Form.Control
                as="textarea"
                size="lg"
                rows={10}
                value={template}
                onChange={(e) => setTemplate(e.target.value)}
              />
            </div>
          </Collapse>
        </Card.Body>
      </Card>
    </>
  );
};

export default ClaimDetails;
