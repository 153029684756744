import React from "react";
import { Button } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

const Page403 = () => (
  <React.Fragment>
    <Helmet title="403 Error" />
    <div className="text-center">
      <h1 className="display-1 fw-bold">403</h1>
      <p className="h1">Access Denied.</p>
      <p className="h2 fw-normal mt-3 mb-4">
        We're sorry, but this page is restricted to administrators only. If you
        believe you should have access, please contact our support team for
        assistance.
      </p>
      <Link to="/">
        <Button variant="primary" size="lg">
          Return to website
        </Button>
      </Link>
    </div>
  </React.Fragment>
);

export default Page403;
