import { Formik } from "formik";
import { useState } from "react";
import { Alert, Button, Collapse, Form, InputGroup } from "react-bootstrap";
import { AlertOctagon, Eye, EyeOff, Smile } from "react-feather";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import authService from "../../services/auth.service";

import useAuth from "../../hooks/useAuth";

const SetPassword = ({ token }) => {
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <>
      <div className="text-center">
        <h2>Reset Password</h2>
        <p className="lead">
          Enter a new password. It must contain at least one letter, one number,
          and one special character.
        </p>
        <p className="lead">
          Once you reset your password, you will have to sign back in.
        </p>
      </div>
      <Formik
        initialValues={{
          password: "",
          confirmPassword: "",
        }}
        initialStatus={{ success: false }}
        validationSchema={Yup.object().shape({
          password: Yup.string()
            .max(50, "Password can be at most 50 characters long")
            .matches(
              /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/,
              "Password must contain at least one letter, one number, and one special character."
            )
            .when([], (schema) => schema.required("Password is required.")),
          confirmPassword: Yup.string().when("password", (password, schema) => {
            if (password) {
              return schema
                .required("Confirm Password is required.")
                .oneOf([Yup.ref("password"), null], "Passwords must match.");
            }
            return schema;
          }),
        })}
        onSubmit={async (values, { setErrors, setStatus }) => {
          const response = await authService.resetPassword(
            token,
            values.password
          );

          if (response.success) {
            setStatus(response);

            setTimeout(() => {
              signOut();
              navigate("/");
            }, 3000);
          } else {
            setStatus({ success: false });
            setErrors({ submit: response.message });
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          status,
        }) => (
          <Form onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert variant="danger" className="flex-fill alert-blink">
                <div className="alert-icon">
                  <AlertOctagon
                    className="position-relative top-50 start-50 translate-middle"
                    size={20}
                  />
                </div>
                <div className="alert-message font-weight-bold">
                  {errors.submit}
                </div>
              </Alert>
            )}
            {status.success && (
              <Alert variant="success" className="flex-fill alert-blink">
                <div className="alert-icon">
                  <Smile
                    className="position-relative top-50 start-50 translate-middle"
                    size={20}
                  />
                </div>
                <div className="alert-message font-weight-bold">
                  {status.message}
                </div>
              </Alert>
            )}

            <Form.Label className="mb-0">
              New Password<span className="text-danger ms-1">*</span>
            </Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type={showPassword ? "text" : "password"}
                name="password"
                size="lg"
                placeholder="Enter Password..."
                value={values.password}
                onBlur={handleBlur}
                onChange={handleChange}
                isInvalid={Boolean(touched.password && errors.password)}
                disabled={isSubmitting}
              />
              <Button
                variant="outline-secondary"
                size="lg"
                onClick={togglePassword}
                disabled={isSubmitting}
              >
                {showPassword ? <EyeOff /> : <Eye />}
              </Button>
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </InputGroup>

            <Collapse in={Boolean(values.password)}>
              <div>
                <Form.Label className="mt-3">
                  Confirm Password<span className="text-danger ms-1">*</span>
                </Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    name="confirmPassword"
                    size="lg"
                    placeholder="Re-Enter Password..."
                    value={values.confirmPassword}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isInvalid={Boolean(
                      touched.confirmPassword && errors.confirmPassword
                    )}
                    disabled={isSubmitting}
                  />
                  <Button
                    variant="outline-secondary"
                    size="lg"
                    onClick={togglePassword}
                    disabled={isSubmitting}
                  >
                    {showPassword ? <EyeOff /> : <Eye />}
                  </Button>
                  <Form.Control.Feedback type="invalid">
                    {errors.confirmPassword}
                  </Form.Control.Feedback>
                </InputGroup>
              </div>
            </Collapse>

            <div className="text-center mt-3">
              <Button
                type="submit"
                variant="primary"
                size="lg"
                disabled={isSubmitting || status.success}
              >
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SetPassword;
