import { Formik } from "formik";
import React, { useContext } from "react";
import { Alert, Button, Card, Form, InputGroup, Modal } from "react-bootstrap";
import { AlertOctagon, AlertTriangle, Smile } from "react-feather";
import * as Yup from "yup";

import authService from "../../services/auth.service";
import { UsersContext } from "./Users";

const InviteUserModal = ({ showInviteUserModal, setShowInviteUserModal }) => {
  const { submitting, isSuper, organization } = useContext(UsersContext);

  return (
    <Modal
      show={showInviteUserModal}
      onHide={() => setShowInviteUserModal(false)}
      keyboard={false}
      size="sm"
    >
      <Card.Header className="border-bottom ">
        <Card.Title>Invite New User</Card.Title>
        <Card.Subtitle className="text-muted">
          Invite a team member by email to join your organization. Once they
          finish creating their account, they will appear on the Users Table.
        </Card.Subtitle>
        {isSuper && organization && (
          <Alert variant="warning" className="flex-fill alert-blink mt-3 mb-0">
            <div className="alert-icon">
              <AlertTriangle
                className="position-relative top-50 start-50 translate-middle"
                size={20}
              />
            </div>
            <div className="alert-message font-weight-bold">
              <strong>NOTE: </strong>This will send an invite to join{" "}
              <strong>{organization.name}</strong>.
            </div>
          </Alert>
        )}
      </Card.Header>
      <Card.Body>
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Invalid email.")
              .max(255)
              .required("Email is required."),
          })}
          onSubmit={async (values, { setStatus }) => {
            const response = await authService.sendInvite(values.email);

            if (response.success) {
              setStatus({ success: true, message: response.message });
              setTimeout(() => {
                setShowInviteUserModal(false);
              }, 5000);
            } else {
              setStatus({ success: false, message: response.message });
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            dirty,
            values,
            status,
          }) => (
            <Form onSubmit={handleSubmit}>
              {status?.success && (
                <Alert variant="success" className="flex-fill alert-blink">
                  <div className="alert-icon">
                    <Smile
                      className="position-relative top-50 start-50 translate-middle"
                      size={20}
                    />
                  </div>
                  <div className="alert-message font-weight-bold">
                    {status.message}
                  </div>
                </Alert>
              )}
              {status?.success === false && (
                <Alert variant="danger" className="flex-fill alert-blink">
                  <div className="alert-icon">
                    <AlertOctagon
                      className="position-relative top-50 start-50 translate-middle"
                      size={20}
                    />
                  </div>
                  <div className="alert-message font-weight-bold">
                    {status.message}
                  </div>
                </Alert>
              )}
              <Form.Label className="mb-0">
                Email<span className="text-danger ms-1">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  name="email"
                  placeholder="Enter email"
                  value={values.email}
                  isInvalid={Boolean(touched.email && errors.email)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={isSubmitting || status?.success}
                />
                <Button
                  type="submit"
                  variant="primary"
                  disabled={
                    isSubmitting || !dirty || errors.email || status?.success
                  }
                >
                  Send
                </Button>
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </InputGroup>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Modal>
  );
};

export default InviteUserModal;
