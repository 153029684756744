import { Dropdown, Image } from "react-bootstrap";
import { Settings } from "react-feather";
import { useNavigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";

const NavbarUser = () => {
  const navigate = useNavigate();
  const { signOut, user, organization } = useAuth();

  return (
    <Dropdown className="nav-item" align="end">
      <span className="d-inline-block d-sm-none">
        <Dropdown.Toggle as="a" className="nav-link">
          <Settings size={18} className="align-middle" />
        </Dropdown.Toggle>
      </span>
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link">
          <Image
            fluid
            style={{ maxHeight: "40px" }}
            className="rounded border me-2"
            src={organization?.logo}
          />
          <span className="text-dark me-1">{`${user?.firstName} ${user?.lastName}`}</span>
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu drop="end">
        <Dropdown.Item onClick={() => navigate("/settings")}>
          Settings
        </Dropdown.Item>
        <Dropdown.Item onClick={signOut}>Sign out</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarUser;
