import axios from "axios";
import { handleError } from "../utils/helper";

const HOST_URL = process.env.REACT_APP_HOST_URL;

/**
 * Retrieves users from the server.
 *
 * @async
 * @function getUsers
 * @returns {Promise<Array>} A promise that resolves to an array of user data.
 * @throws {Error} If an error occurs during the API request.
 */
const getUsers = async () => {
  try {
    return (await axios.get(`${HOST_URL}/portal/users`)).data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Updates user information on the server.
 *
 * @async
 * @function updateUser
 * @param {string} userId - The ID of the user to be updated.
 * @param {Object} userData - The updated user data.
 * @returns {Promise<Object>} A promise that resolves to the updated user data.
 * @throws {Error} If an error occurs during the API request.
 */
const updateUser = async (userId, userData) => {
  try {
    return (await axios.patch(`${HOST_URL}/portal/users/${userId}`, userData))
      .data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Creates a new user on the server.
 *
 * @async
 * @function createUser
 * @param {Object} userData - The data of the user to be created.
 * @returns {Promise<Object>} A promise that resolves to the newly created user data.
 * @throws {Error} If an error occurs during the API request.
 */
const createUser = async (userData) => {
  try {
    return (await axios.post(`${HOST_URL}/portal/users`, userData)).data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Deletes a user from the server.  In order to preserve user data, the
 * user is only deactivated, but this is still essentially deleting the user.
 *
 * @async
 * @function deleteUser
 * @param {string} userId - The ID of the user to be deleted.
 * @returns {Promise<Object>} A promise that resolves when the user is successfully deleted.
 * @throws {Error} If an error occurs during the API request.
 */
const deactivateUser = async (userId) => {
  try {
    return (await axios.delete(`${HOST_URL}/portal/users/${userId}`)).data;
  } catch (error) {
    return handleError(error);
  }
};

const services = {
  getUsers,
  updateUser,
  createUser,
  deactivateUser,
};

export default services;
