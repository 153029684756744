import React, { createContext, useEffect, useState } from "react";
import { Col, Container, Fade, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

import Loader from "../../components/Loader";
import ClaimDetails from "./ClaimDetails";
import ClaimImages from "./ClaimImages";
import ClaimsTable from "./ClaimsTable";
import ReportDetails from "./ReportDetails";

import { handleScroll } from "../../utils/helper";

import claimsService from "../../services/claims.service";

import useAuth from "../../hooks/useAuth";

const ClaimsContext = createContext();

const Claims = () => {
  const { user, organization } = useAuth();

  const isSuper = user?.isSuper;
  const isAdmin = user?.isAdmin;

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [claims, setClaims] = useState();
  const [selectedClaim, setSelectedClaim] = useState();

  const [claimStatusOptions, setClaimStatusOptions] = useState();

  const { claimId } = useParams();

  useEffect(() => {
    if (user) {
      initialize();
    }
  }, [user]);

  useEffect(() => {
    if (claimId && claims) {
      setSelectedClaim(
        claims.find((claim) => Number(claim.claimId) === Number(claimId))
      );
    }
  }, [claims]);

  useEffect(() => {
    handleScroll("claim-details");
  }, [selectedClaim]);

  const initialize = async () => {
    setLoading(true);
    await getClaims();
    await getClaimStatusOptions();
    setLoading(false);
  };

  const getClaims = async () => {
    const response = await claimsService.getClaims();

    if (response.success) {
      if (!isAdmin) {
        const filteredClaims = response.claims.filter(
          (claim) => claim.userId === user.userId
        );
        setClaims(filteredClaims);
      } else {
        setClaims(response.claims);
      }
    } else {
      console.log(response);
      toast.error("Something went wrong, please refresh the page.", {
        duration: 30000,
      });
    }
  };

  const getClaimStatusOptions = async () => {
    const response = await claimsService.getClaimStatuses();

    if (response.success) {
      const options = response.claimStatuses.map((claimStatus) => {
        return { value: claimStatus.statusId, label: claimStatus.status };
      });
      setClaimStatusOptions(options);
    } else {
      console.log(response);
      toast.error("Something went wrong, please refresh the page.", {
        duration: 30000,
      });
    }
  };

  return (
    <ClaimsContext.Provider
      value={{
        isSuper,
        isAdmin,
        user,
        organization,
        submitting,
        setSubmitting,
        claims,
        setClaims,
        selectedClaim,
        setSelectedClaim,
        claimStatusOptions,
      }}
    >
      <Helmet title="Claims" />
      <Container fluid className="p-0">
        <h1 className="mb-3">Claims</h1>

        <Row>
          <Col xl={8}>
            {!claims && loading && (
              <Fade in={loading}>
                <div>
                  <Loader />
                </div>
              </Fade>
            )}
            <Fade in={Boolean(claims)}>
              <div>{claims && <ClaimsTable />}</div>
            </Fade>
            <Fade in={Boolean(selectedClaim)}>
              <div id="claim-details">{selectedClaim && <ClaimDetails />}</div>
            </Fade>
            <Fade in={Boolean(selectedClaim?.report)}>
              <div>{selectedClaim?.report && <ReportDetails />}</div>
            </Fade>
          </Col>

          <Col xl={4}>
            <Fade in={Boolean(selectedClaim?.report)}>
              <div className="sticky-top" style={{ top: 20 }}>
                {selectedClaim?.report?.reportImages && <ClaimImages />}
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </ClaimsContext.Provider>
  );
};

export { ClaimsContext };

export default Claims;
