import React from "react";

import PerfectScrollbar from "react-perfect-scrollbar";
import { Image } from "react-bootstrap";

import useSidebar from "../../hooks/useSidebar";
import SidebarFooter from "./SidebarFooter";
import SidebarNav from "./SidebarNav";
import { ReactComponent as LogoColor } from "../../assets/img/rww-brandmark-fullcolor.svg";
import { ReactComponent as LogoWhite } from "../../assets/img/rww-brandmark-white.svg";
import logo from "../../assets/img/claims_pro_logo_color.svg";
import useTheme from "../../hooks/useTheme";

const Sidebar = ({ items, showFooter = true }) => {
  const { isOpen } = useSidebar();
  const { theme } = useTheme();

  return (
    <nav className={`sidebar ${!isOpen ? "collapsed" : ""}`}>
      <div className="sidebar-content">
        <PerfectScrollbar>
          <a className="sidebar-brand" href="/">
            {/* {theme === "light" ? <LogoColor /> : <LogoWhite />}
            <span className="align-middle ms-2">Returns Worldwide</span> */}
            <Image src={logo} alt="ClaimsPro logo" className="img-fluid" />
          </a>

          <SidebarNav items={items} />
          {!!showFooter && <SidebarFooter />}
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default Sidebar;
