import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Card, Image, Stack, Alert } from "react-bootstrap";
import { Check, X } from "react-feather";
import toast from "react-hot-toast";
import { Building, Pencil, Power, PowerOff, AlertCircle } from "lucide-react";

import OrganizationModal from "./OrganizationModal";

import organizationsService from "../../services/organizations.service";

import { OrganizationsContext } from "./Organizations";

const OrganizationDetails = () => {
  const [error, setError] = useState();

  const {
    submitting,
    setSubmitting,
    setShowOrganizationModal,
    setOrganizations,
    selectedOrganization,
    setSelectedOrganization,
    setIsEdit,
  } = useContext(OrganizationsContext);

  useEffect(() => {
    setShowOrganizationModal(false);
    setError();
  }, [selectedOrganization]);

  const handleDeactivateOrganization = async () => {
    setError();
    setSubmitting(true);

    const result = await organizationsService.deactivateOrganization(
      selectedOrganization.organizationId
    );

    if (result?.success) {
      toast.success("Organization successfully deactivated.");

      setOrganizations((prevState) => {
        return prevState
          .map((stateOrg) => {
            if (
              stateOrg.organizationId ===
              result.deactivatedOrganization.organizationId
            ) {
              return result.deactivatedOrganization;
            }
            return stateOrg;
          })
          .sort((a, b) => {
            if (a.isActive && b.isActive) {
              return 0;
            }
            if (a.isActive) {
              return -1;
            }
            if (b.isActive) {
              return 1;
            }
            return a.isActive - b.isActive;
          });
      });

      setSelectedOrganization(result.deactivatedOrganization);
    } else {
      toast.error("Something went wrong. Please try again.");

      setError(result);
    }

    setSubmitting(false);
  };

  const handleReactivateOrganization = async () => {
    setError();
    setSubmitting(true);

    const result = await organizationsService.updateOrganization(
      selectedOrganization.organizationId,
      { isActive: true }
    );

    if (result?.success) {
      toast.success("Organization successfully reactivated.");

      setOrganizations((prevState) => {
        return prevState
          .map((stateOrg) => {
            if (
              stateOrg.organizationId ===
              result.updatedOrganization.organizationId
            ) {
              return result.updatedOrganization;
            }
            return stateOrg;
          })
          .sort((a, b) => {
            if (a.isActive && b.isActive) {
              return 0;
            }
            if (a.isActive) {
              return -1;
            }
            if (b.isActive) {
              return 1;
            }
            return a.isActive - b.isActive;
          });
      });

      setSelectedOrganization(result.updateOrganization);
    } else {
      toast.error("Something went wrong. Please try again.");

      setError(result);
    }

    setSubmitting(false);
  };

  // The data to display.
  const { logo, name, phoneNumber, isActive } = selectedOrganization;

  return (
    <>
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-start mb-3">
            <Building size={48} className="me-2" />
            <div>
              <Card.Title>Organization Details</Card.Title>
              <Card.Subtitle>
                Additional details for <strong>{name}</strong>.
              </Card.Subtitle>
            </div>
          </div>
          <Stack direction="horizontal" gap={3}>
            <Button
              variant="primary"
              onClick={() => {
                setIsEdit(true);
                setShowOrganizationModal(true);
              }}
              disabled={submitting}
            >
              <Pencil className="me-2" size={18} />
              Edit
            </Button>
            {isActive ? (
              <Button
                variant="danger"
                onClick={() => {
                  handleDeactivateOrganization();
                  setError();
                }}
                disabled={submitting}
              >
                <PowerOff className="me-2" size={18} />
                Deactivate
              </Button>
            ) : (
              <Button
                variant="warning"
                onClick={() => {
                  handleReactivateOrganization();
                  setError();
                }}
                disabled={submitting}
              >
                <Power className="me-2" size={18} />
                Reactivate
              </Button>
            )}
          </Stack>
          {error && (
            <Alert variant="danger" className="flex-fill alert-blink mt-3 mb-0">
              <div className="alert-icon">
                <AlertCircle
                  className="position-relative top-50 start-50 translate-middle"
                  size={20}
                />
              </div>
              <div className="alert-message font-weight-bold">
                {error.message}
              </div>
            </Alert>
          )}
        </Card.Header>
        <Card.Body>
          <>
            {logo && (
              <div className="mb-3">
                <span className="d-block mb-0">Logo</span>
                <Image
                  className="img-thumbnail"
                  style={{ maxHeight: "75px" }}
                  src={logo}
                  // onError={({ currentTarget }) => {
                  //   currentTarget.onerror = null; // prevents looping
                  //   currentTarget.src = imageNotFound;
                  // }}
                  alt="Organization Logo"
                />
              </div>
            )}

            <div className="mb-3">
              <span className="d-block mb-0">Name</span>
              <h4>{name}</h4>
            </div>

            <div className="mb-3">
              <span className="d-block mb-0">Phone Number</span>
              <h4>{phoneNumber}</h4>
            </div>

            <div className="mb-3">
              <span className="d-block mb-0">Active</span>
              {isActive ? (
                <Check color="#4BBF73" size={24} />
              ) : (
                <X color="#d9534f" size={24} />
              )}
            </div>
          </>
        </Card.Body>
      </Card>
    </>
  );
};

export default OrganizationDetails;
