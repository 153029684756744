import {
  ClipboardList,
  ClipboardPlus,
  Copy,
  Frown,
  Meh,
  Navigation,
  Save,
  Smile,
  UserRoundCheck,
} from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Row,
  Spinner,
  Stack,
} from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

import ClaimModal from "./ClaimModal";

import reportsService from "../../services/reports.service";

import { ReportsContext } from "./Reports";

const ReportDetails = () => {
  const navigate = useNavigate();

  const [showClaimModal, setShowClaimModal] = useState(false);

  const {
    isAdmin,
    user,
    submitting,
    setSubmitting,
    setReports,
    selectedReport,
    setSelectedReport,
    userOptions,
  } = useContext(ReportsContext);

  useEffect(() => {
    setAssignedValue("");
  }, [selectedReport]);

  const {
    reportId,
    lpn,
    fnsku,
    removalOrderId,
    reason,
    subReason,
    reasonNote,
    reporter,
    vendor,
    assignedUserId,
    assignedUser,
    statusId,
    status,
    claimId,
    createdAt,
    updatedAt,
  } = selectedReport;

  const [assignedValue, setAssignedValue] = useState(assignedUserId || -1);

  const handleGoToClaim = async () => {
    navigate(`/claims/${claimId}`);
  };

  const handleChangeStatus = async (newStatusId) => {
    setSubmitting(true);

    const response = await reportsService.updateReport(reportId, {
      statusId: newStatusId,
    });

    if (response.success) {
      setReports((prevState) => {
        return prevState.map((stateReport) => {
          if (stateReport.reportId === response.updatedReport.reportId) {
            stateReport = response.updatedReport;
          }
          return stateReport;
        });
      });
      setSelectedReport(response.updatedReport);

      toast.success("Successfully changed the report status!");
      setSubmitting(false);
    } else {
      console.log(response);
      toast.error("Something went wrong changing the claim status.");
      setSubmitting(false);
    }
  };

  const handleChangeAssignedUser = async (userId) => {
    setSubmitting(true);

    const response = await reportsService.updateReport(reportId, {
      assignedUserId: userId,
    });

    if (response.success) {
      setReports((prevState) => {
        return prevState.map((stateReport) => {
          if (stateReport.reportId === response.updatedReport.reportId) {
            stateReport = response.updatedReport;
          }
          return stateReport;
        });
      });
      setSelectedReport(response.updatedReport);

      toast.success("Successfully changed the report assignee!");
      setSubmitting(false);
    } else {
      console.log(response);
      toast.error("Something went wrong changing the report assignee.");
      setSubmitting(false);
    }
  };

  return (
    <>
      <ClaimModal
        showClaimModal={showClaimModal}
        setShowClaimModal={setShowClaimModal}
      />
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <div className="d-flex justify-content-start">
            <ClipboardList className="me-2" size={48} />
            <div>
              <Card.Title>Inspection Report Details</Card.Title>
              <Card.Subtitle>
                Additional details about the selected inspection report.
              </Card.Subtitle>
              <p className="lead">{lpn}</p>
            </div>
          </div>
          <Stack className="ms-3" gap={3}>
            {/* <InputGroup className="mb-3">
              <InputGroup.Text>Assign To</InputGroup.Text>
              <div className="form-control p-0">
              <Select
                // styles={{
                //   container: (baseStyles) => ({
                //     ...baseStyles,
                //     margin: "-1px",
                //     padding: "-1px",
                //   }),
                //   control: (baseStyles) => ({
                //     ...baseStyles,
                //     borderRadius: "0",
                //     margin: "-1px",
                //     padding: "-1px",
                //   }),
                // }}
                styles={{
                  container: (base) => ({
                    ...base,
                    height: formControlHeight,
                    minHeight: formControlHeight,
                    padding: 0,
                    margin: 0,
                  }),
                  control: (base) => ({
                    ...base,
                    height: formControlHeight,
                    minHeight: formControlHeight,
                    borderRadius: 0,
                    padding: formControlPadding,
                    border: "1px solid #ced4da", // Match Bootstrap's border color
                    boxShadow: "none", // Match Bootstrap's default box-shadow
                    "&:hover": {
                      borderColor: "#adb5bd", // Match Bootstrap's hover border color
                    },
                  }),
                  menu: (base) => ({
                    ...base,
                    border: "1px solid #ced4da", // Match Bootstrap's border color
                    borderRadius: "0.25rem", // Match Bootstrap's border-radius
                    marginTop: 0,
                  }),
                  menuList: (base) => ({
                    ...base,
                    maxHeight: "200px", // Ensure the menu does not get too tall
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isSelected ? "#e9ecef" : "#fff", // Match Bootstrap's selected background color
                    color: "#495057", // Match Bootstrap's text color
                    padding: "0.5rem 1rem",
                    "&:hover": {
                      backgroundColor: "#e9ecef", // Match Bootstrap's hover background color
                    },
                  }),
                  placeholder: (base) => ({
                    ...base,
                    color: "#6c757d", // Match Bootstrap's placeholder color
                  }),
                  singleValue: (base) => ({
                    ...base,
                    color: "#495057", // Match Bootstrap's text color
                  }),
                }}
                className={`react-select-container${
                  submitting ? "-disabled" : ""
                }`}
                classNamePrefix="react-select"
                placeholder="Select a user..."
                onChange={(selectedOption) => {
                  setAssignedValue(selectedOption);
                }}
                value={assignedValue}
                options={userOptions}
                isSearchable
                isLoading={!userOptions}
              />
              </div>
              <Button
                variant="outline-secondary"
                onClick={() => handleChangeAssignedUser(assignedValue)}
                disabled={
                  submitting ||
                  !assignedValue ||
                  Number(assignedValue) === Number(assignedUserId)
                }
              >
                <Save size={18} />
              </Button>
            </InputGroup> */}
            {isAdmin && (
              <InputGroup>
                <InputGroup.Text>Assign To</InputGroup.Text>
                <Form.Select
                  onChange={(e) => {
                    setAssignedValue(Number(e.target.value));
                  }}
                  value={assignedValue}
                  disabled={submitting}
                >
                  {userOptions.map((userOption, index) => {
                    return (
                      <option key={index} value={userOption.value}>
                        {userOption.label}
                      </option>
                    );
                  })}
                </Form.Select>
                <Button
                  variant="outline-secondary"
                  onClick={() => handleChangeAssignedUser(assignedValue)}
                  disabled={
                    submitting ||
                    !assignedValue ||
                    Number(assignedValue) === Number(assignedUserId)
                  }
                >
                  <Save size={18} />
                </Button>
              </InputGroup>
            )}
            <div className="ms-auto">
              {!isAdmin && assignedUserId !== user.userId && (
                <Button
                  variant="primary"
                  onClick={() => handleChangeAssignedUser(user.userId)}
                  disabled={submitting || assignedUserId}
                >
                  <UserRoundCheck className="me-2" size={18} />
                  Assign to Self
                </Button>
              )}
              {claimId ? (
                <Button
                  variant="primary"
                  onClick={handleGoToClaim}
                  disabled={submitting}
                >
                  <Navigation className="me-2" size={18} />
                  Go To Claim
                </Button>
              ) : (
                assignedUserId && (
                  <Button
                    variant="primary"
                    onClick={() => {
                      setShowClaimModal(true);
                    }}
                    disabled={
                      statusId === 3 ||
                      (!isAdmin && assignedUserId !== user.userId)
                    }
                  >
                    <ClipboardPlus className="me-2" size={18} />
                    Create Claim
                  </Button>
                )
              )}
            </div>
          </Stack>
        </Card.Header>
        <Card.Body className="pt-0">
          <hr className="mt-0" />
          <Row className="gy-3" xs={2} md={3}>
            <Col>
              <Form.Label className="mb-0">LPN</Form.Label>
              <InputGroup>
                <div className="fw-bold form-control text-nowrap overflow-hidden">
                  {lpn}
                </div>

                <CopyToClipboard
                  text={lpn}
                  onCopy={() => {
                    toast.success("LPN successfully copied to clipboard!");
                  }}
                >
                  <Button variant="outline-secondary" disabled={showClaimModal}>
                    <Copy size={20} />
                  </Button>
                </CopyToClipboard>
              </InputGroup>
            </Col>
            <Col>
              <Form.Label className="mb-0">FNSKU</Form.Label>
              <div className="fw-bold form-control">
                {fnsku ? fnsku : "TBD"}
              </div>
            </Col>
            <Col>
              <Form.Label className="mb-0">Removal Order ID</Form.Label>
              <div className="fw-bold form-control">
                {removalOrderId ? removalOrderId : "TBD"}
              </div>
            </Col>
            <Col>
              <Form.Label className="mb-0">Reason</Form.Label>
              <div className="fw-bold form-control">{reason}</div>
            </Col>
            <Col>
              <Form.Label className="mb-0">Sub Reason</Form.Label>
              <div className="fw-bold form-control">{subReason}</div>
            </Col>
            <Col>
              <Form.Label className="mb-0">Reason Note</Form.Label>
              <div className="fw-bold form-control">
                {reasonNote ? reasonNote : "None"}
              </div>
            </Col>
            <Col>
              <Form.Label className="mb-0">Vendor</Form.Label>
              <div className="fw-bold form-control">{vendor}</div>
            </Col>
            <Col>
              <Form.Label className="mb-0">Reporter</Form.Label>
              <div className="fw-bold form-control">{reporter}</div>
            </Col>
            <Col>
              <Form.Label className="mb-0">Assigned To</Form.Label>
              <div className="fw-bold form-control">{assignedUser}</div>
            </Col>
            <Col>
              <Form.Label className="mb-0">Created At</Form.Label>
              <div className="fw-bold form-control">
                {new Date(createdAt).toLocaleString("en-US")}
              </div>
            </Col>
            <Col>
              <Form.Label className="mb-0">Updated At</Form.Label>
              <div className="fw-bold form-control">
                {new Date(updatedAt).toLocaleString("en-US")}
              </div>
            </Col>
            <Col>
              <Form.Label className="mb-0">Status</Form.Label>
              <InputGroup className="mb-3" style={{ zIndex: 1030 }}>
                <div className="fw-bold form-control text-nowrap overflow-hidden">
                  {status}
                </div>
                <DropdownButton
                  variant={
                    statusId === 1
                      ? "outline-warning"
                      : statusId === 2
                      ? "outline-success"
                      : "outline-danger"
                  }
                  title={
                    submitting ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : statusId === 1 ? (
                      <Meh size={20} />
                    ) : statusId === 2 ? (
                      <Smile size={20} />
                    ) : (
                      <Frown size={20} />
                    )
                  }
                  align="end"
                  disabled={
                    submitting ||
                    Number(assignedUserId) !== Number(user.userId) ||
                    showClaimModal ||
                    statusId === 2 // If claim is already created, no changing eligibility
                  }
                >
                  <Dropdown.Header>Change Status To</Dropdown.Header>

                  {statusId === 3 ? ( // If the status is Not eligible, show this option.
                    <Dropdown.Item
                      onClick={() => {
                        if (claimId) {
                          // If report has a claim, change back to claim created.
                          handleChangeStatus(2);
                        } else {
                          handleChangeStatus(1);
                        }
                      }}
                    >
                      "Eligible"
                    </Dropdown.Item>
                  ) : (
                    // Otherwise, show this option.
                    <Dropdown.Item onClick={() => handleChangeStatus(3)}>
                      "Not Eligible"
                    </Dropdown.Item>
                  )}
                </DropdownButton>
              </InputGroup>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default ReportDetails;
