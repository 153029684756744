import { Files } from "lucide-react";
import React, { useContext } from "react";
import { Card } from "react-bootstrap";

import Table from "../../components/table/Table";

import tableColumns from "./tableColumns";

import { ClaimsContext } from "./Claims";

const ClaimsTable = () => {
  const { isSuper, isAdmin, user, claims, organization, setSelectedClaim } =
    useContext(ClaimsContext);

  const handleRowClick = async (row) => {
    setSelectedClaim(row);
  };

  // Filter the table columns based on access.
  const columns = isSuper
    ? tableColumns
    : isAdmin
    ? tableColumns.filter(
        (col) => col.accessor !== "claimId" && col.accessor !== "organization"
      )
    : tableColumns.filter(
        (col) =>
          col.accessor !== "claimId" &&
          col.accessor !== "user" &&
          col.accessor !== "organization"
      );

  return (
    <Card>
      <Card.Header className="d-flex justify-content-start">
        <Files className="me-3" size={48} />
        <div>
          <Card.Title>Claims</Card.Title>
          <Card.Subtitle>
            {isSuper
              ? "All the claims created by every organization."
              : isAdmin
              ? `All the claims created by ${organization.name}.`
              : `All the claims created by ${user.firstName} ${user.lastName}.`}
          </Card.Subtitle>
        </div>
      </Card.Header>
      <Card.Body>
        <Table columns={columns} data={claims} onClickFn={handleRowClick} />
      </Card.Body>
    </Card>
  );
};

export default ClaimsTable;
