import jwtDecode from "jwt-decode";
import { verify, sign } from "jsonwebtoken";
import axios from "axios";

const isValidToken = (token) => {
  try {
    if (!token) {
      return false;
    }
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const setSession = (accessToken, refreshToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axios.defaults.headers.common.RefreshToken = `RefreshToken ${refreshToken}`;
  } else {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");

    delete axios.defaults.headers.common.Authorization;
    delete axios.defaults.headers.common.RefreshToken;
  }
};

const updateSession = (accessToken, refreshToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }

  if (refreshToken) {
    localStorage.setItem("refreshToken", refreshToken);

    axios.defaults.headers.common.RefreshToken = `RefreshToken ${refreshToken}`;
  }
};

export { verify, sign, isValidToken, setSession, updateSession };
