import React from "react";

// All pages that rely on 3rd party components (other than Bootstrap) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";
import AdminGuard from "./components/guards/AdminGuard";
import SuperGuard from "./components/guards/SuperGuard";

// Dashboards
// import Default from "./pages/dashboards/Default";

// Pages
import Blank from "./pages/Blank";
import ComingSoon from "./pages/ComingSoon";
import Settings from "./pages/settings/Settings";
import Reports from "./pages/reports/Reports";
import Claims from "./pages/claims/Claims";
import Users from "./pages/users/Users";
import Organizations from "./pages/organizations/Organizations";

// Auth
import Page400 from "./pages/auth/Page400";
import Page403 from "./pages/auth/Page403";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";

const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Reports />,
      },
      {
        path: "reports",
        element: <Reports />,
      },
      {
        path: "reports/:reportId",
        element: <Reports />,
      },
      {
        path: "claims",
        element: <Claims />,
      },
      {
        path: "claims/:claimId",
        element: <Claims />,
      },
      {
        path: "users",
        element: (
          <AdminGuard>
            <Users />
          </AdminGuard>
        ),
      },
      {
        path: "organizations",
        element: (
          <SuperGuard>
            <Organizations />
          </SuperGuard>
        ),
      },
      {
        path: "settings",
        element: <Settings />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up/:token",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "reset-password/:token",
        element: <ResetPassword />,
      },
      {
        path: "400",
        element: <Page400 />,
      },
      {
        path: "403",
        element: <Page403 />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
