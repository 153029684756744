import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import Wrapper from "../components/Wrapper";
import Sidebar from "../components/sidebar/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/navbar/Navbar";
import Content from "../components/Content";
import Footer from "../components/Footer";

import dashboardItems from "../components/sidebar/dashboardItems";

import useAuth from "../hooks/useAuth";

const Dashboard = ({ children }) => {
  const { checkExpiredRefreshToken } = useAuth();

  useEffect(() => {
    checkExpiredRefreshToken();

    const signOutInterval = setInterval(() => {
      checkExpiredRefreshToken();
    }, 30 * 60000);

    return () => {
      clearInterval(signOutInterval);
    };
  }, []);

  return (
    <React.Fragment>
      <Wrapper>
        <Sidebar items={dashboardItems} />
        <Main>
          <Navbar />
          <Content>
            <Toaster />
            {children}
            <Outlet />
          </Content>
          <Footer />
        </Main>
      </Wrapper>
    </React.Fragment>
  );
};

export default Dashboard;
