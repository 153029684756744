import { Formik } from "formik";
import React, { useContext, useEffect, useState, useRef } from "react";
import { Form, Button, Collapse, Spinner, Stack } from "react-bootstrap";
import toast from "react-hot-toast";
import * as Yup from "yup";
import Select from "react-select";

import usersService from "../../services/users.service";

import { UsersContext } from "./Users";

const UserForm = ({ isEdit, setStatus }) => {
  const [formId] = useState(Math.random().toString());

  const {
    isSuper,
    loading,
    setUsers,
    selectedUser,
    setSelectedUser,
    setShowUserModal,
    organizationOptions,
  } = useContext(UsersContext);

  const fileInputRef = useRef(null); // Ref for the file input

  const [selectedOption, setSelectedOption] = useState();

  // Set the validation conditionally.
  const BaseSchema = Yup.object().shape({
    // avatar: Yup.mixed().test(
    //   "fileFormat",
    //   "File must be of type PNG or JPEG.",
    //   (value) => {
    //     if (value) {
    //       return value.type === "image/png" || value.type === "image/jpeg";
    //     }
    //     return true; // No file selected, so validation passes
    //   }
    // ),
    firstName: Yup.string()
      .max(50, "First Name must be less than 50 characters long.")
      .required("First Name is required."),
    lastName: Yup.string()
      .max(50, "Last Name must be less than 50 characters long.")
      .required("Last Name is required."),
    email: Yup.string()
      .email("Invalid email")
      .max(255)
      .required("Email is required"),
  });

  const SuperSchema = Yup.object().shape({
    organizationId: Yup.number().required("Organization is required."),
    password: Yup.string()
      .max(50, "Password can be at most 50 characters long")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/,
        "Password must contain at least one letter, one number, and one special character."
      )
      .when([], (schema) =>
        !isEdit ? schema.required("Password is required.") : schema
      ),
    confirmPassword: Yup.string().when("password", (password, schema) => {
      if (password) {
        return schema
          .required("Confirm Password is required.")
          .oneOf([Yup.ref("password"), null], "Passwords must match.");
      }
      return schema;
    }),
  });

  const ValidationSchema = isSuper
    ? BaseSchema.concat(SuperSchema)
    : BaseSchema;

  // Set the initial values conditionally.
  const baseValues = {
    firstName: selectedUser?.firstName || "",
    lastName: selectedUser?.lastName || "",
    email: selectedUser?.email || "",
    isAdmin: selectedUser?.isAdmin || false,
  };

  const superValues = {
    password: "",
    confirmPassword: "",
    organizationId: selectedUser?.organizationId || "",
    hasApiAccess: selectedUser?.hasApiAccess || false,
  };

  const initialValues = isSuper
    ? { ...baseValues, ...superValues }
    : baseValues;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={async (values, actions) => {
        if (isEdit) {
          // Combine the selected user and values together. If a new password is being submitted,
          // use the new password, otherwise keep the original password.
          const combinedValues = {
            ...selectedUser,
            ...values,
            password: selectedUser.password,
            newPassword: values.password,
          };
          delete combinedValues.confirmPassword;

          const result = await usersService.updateUser(
            selectedUser.userId,
            combinedValues
          );

          if (result?.success) {
            toast.success("User has successfully been updated!");

            setUsers((prevState) => {
              return prevState
                .map((stateUser) => {
                  if (stateUser.userId === result.updatedUser.userId) {
                    return result.updatedUser;
                  }
                  return stateUser;
                })
                .sort((a, b) => {
                  if (a.isActive && b.isActive) {
                    return 0;
                  }
                  if (a.isActive) {
                    return -1;
                  }
                  if (b.isActive) {
                    return 1;
                  }
                  return a.isActive - b.isActive;
                });
            });

            setSelectedUser(result.updatedUser);
            setShowUserModal(false);
          } else {
            toast.error("Something went wrong. Please try again.");

            if (result.message.includes("Email")) {
              actions.setErrors({ email: result.message });
            } else {
              setStatus({
                success: false,
                message: result.message,
              });
            }
          }
        } else {
          const result = await usersService.createUser(values);

          if (result?.success) {
            toast.success("User has successfully been created!");

            setUsers((prevState) => {
              return prevState.concat(result.newUser).sort((a, b) => {
                if (a.isActive && b.isActive) {
                  return 0;
                }
                if (a.isActive) {
                  return -1;
                }
                if (b.isActive) {
                  return 1;
                }
                return a.isActive - b.isActive;
              });
            });

            setShowUserModal(false);
          } else {
            toast.error("Something went wrong. Please try again.");

            if (result.message.includes("Email")) {
              actions.setErrors({ email: result.message });
            } else {
              setStatus({
                success: false,
                message: result.message,
              });
            }
          }
        }
      }}
    >
      {({
        handleChange,
        handleBlur,
        setFieldValue,
        setTouched,
        handleSubmit,
        handleReset,
        touched,
        dirty,
        isSubmitting,
        errors,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          {isSuper && (
            <Form.Group className="mb-3">
              <Form.Label className="m-0">
                Organization
                {!isEdit && <span className="text-danger ms-1">*</span>}
              </Form.Label>
              <Select
                className={`react-select-container${
                  loading || isSubmitting
                    ? "-disabled"
                    : touched.organizationId && errors.organizationId
                    ? "-invalid"
                    : ""
                } flex-fill`}
                classNamePrefix="react-select"
                name="organizationId"
                placeholder="Select an organization"
                onChange={(selectedOption) => {
                  setFieldValue("organizationId", selectedOption.value, true);
                }}
                value={
                  organizationOptions.find(
                    (option) => option.value === values.organizationId
                  ) || null
                }
                options={organizationOptions}
                isSearchable
                disabled={isSubmitting}
                isLoading={loading}
                onBlur={() => {
                  setTouched({ ...touched, organizationId: true });
                }}
                isInvalid={Boolean(
                  touched.organizationId && errors.organizationId
                )}
              />
              {touched.organizationId && errors.organizationId ? (
                <div className="invalid-feedback d-block">
                  {errors.organizationId}
                </div>
              ) : null}
            </Form.Group>
          )}

          {/* <Form.Group className="mb-3">
            <Form.Label className="mb-0">Avatar</Form.Label>
            <Form.Control
              id="avatar"
              ref={fileInputRef}
              type="file"
              name="avatar"
              onBlur={handleBlur}
              onChange={(e) => {
                setFieldValue("avatar", e.currentTarget.files[0]);
              }}
              isInvalid={Boolean(touched.avatar && errors.avatar)}
              accept="image/png, image/jpeg"
              disabled={isSubmitting}
            />
            <Form.Control.Feedback type="invalid">
              {errors.avatar}
            </Form.Control.Feedback>
          </Form.Group> */}

          <Form.Group className="mb-3">
            <Form.Label className="mb-0">
              First Name<span className="text-danger ms-1">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="firstName"
              placeholder="Enter First Name..."
              value={values.firstName}
              onBlur={handleBlur}
              onChange={handleChange}
              isInvalid={Boolean(touched.firstName && errors.firstName)}
              disabled={isSubmitting}
            />
            <Form.Control.Feedback type="invalid">
              {errors.firstName}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="mb-0">
              Last Name<span className="text-danger ms-1">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="lastName"
              placeholder="Enter Last Name..."
              value={values.lastName}
              onBlur={handleBlur}
              onChange={handleChange}
              isInvalid={Boolean(touched.lastName && errors.lastName)}
              disabled={isSubmitting}
            />
            <Form.Control.Feedback type="invalid">
              {errors.lastName}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="m-0">
              Email<span className="text-danger ms-1">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="email"
              placeholder="Enter Email..."
              value={values.email}
              onBlur={handleBlur}
              onChange={handleChange}
              isInvalid={Boolean(touched.email && errors.email)}
              disabled={isSubmitting}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>

          {isSuper && (
            <Form.Group className="mb-3">
              <Form.Label className="m-0">
                Password{!isEdit && <span className="text-danger ms-1">*</span>}
              </Form.Label>
              <Form.Control
                type="text"
                name="password"
                placeholder="Enter Password..."
                value={values.password}
                onBlur={handleBlur}
                onChange={handleChange}
                isInvalid={Boolean(touched.password && errors.password)}
                disabled={isSubmitting}
              />
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </Form.Group>
          )}

          {values.password && isSuper && (
            <Form.Group className="mb-3">
              <Form.Label className="m-0">
                Confirm Password<span className="text-danger ms-1">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="confirmPassword"
                placeholder="Re-Enter Password..."
                value={values.confirmPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                isInvalid={Boolean(
                  touched.confirmPassword && errors.confirmPassword
                )}
                disabled={isSubmitting}
              />
              <Form.Control.Feedback type="invalid">
                {errors.confirmPassword}
              </Form.Control.Feedback>
            </Form.Group>
          )}

          <Form.Group className="mb-3">
            <Form.Label className="m-0">Permissions</Form.Label>
            <Form.Check
              id={`ia-${formId}`}
              name="isAdmin"
              label={
                <span className="cursor-pointer" htmlFor={`ia-${formId}`}>
                  Administrator
                </span>
              }
              type="switch"
              onChange={handleChange}
              value={values.isAdmin}
              checked={values.isAdmin}
              disabled={isSubmitting}
            />
            {isSuper && (
              <Form.Check
                id={`haa-${formId}`}
                name="hasApiAccess"
                label={
                  <span className="cursor-pointer" htmlFor={`haa-${formId}`}>
                    API Access
                  </span>
                }
                type="switch"
                onChange={handleChange}
                value={values.hasApiAccess}
                checked={values.hasApiAccess}
                disabled={isSubmitting}
              />
            )}
          </Form.Group>

          <Stack direction="horizontal">
            <Button
              type="submit"
              variant="outline-primary"
              disabled={isSubmitting || !dirty}
            >
              {isSubmitting ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : isEdit ? (
                "Save"
              ) : (
                "Create"
              )}
            </Button>
            <div className="vr ms-3" />
            <Collapse in={dirty} dimension="width">
              <div>
                <Button
                  className="ms-3"
                  variant="outline-danger"
                  onClick={() => {
                    handleReset();
                    // fileInputRef.current.value = ""; // Uncomment when adding avatar back in
                  }}
                  disabled={isSubmitting || !dirty}
                >
                  Reset
                </Button>
              </div>
            </Collapse>
            <Button
              className="ms-3"
              variant="outline-secondary"
              onClick={() => {
                handleReset();
                setShowUserModal(false);
              }}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default UserForm;
