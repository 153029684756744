import { Formik } from "formik";
import { Alert, Button, Form, InputGroup } from "react-bootstrap";
import { AlertOctagon, Smile } from "react-feather";
import toast from "react-hot-toast";
import * as Yup from "yup";

import authService from "../../services/auth.service";

function SendOtpForm() {
  return (
    <>
      <div className="text-center">
        <h2>Reset Password</h2>
        <p>
          Please enter the email address associated with your account below.
          Click the "Send" button to receive a verification code via email. Once
          you receive the email, follow the instructions to reset your password.
        </p>
      </div>
      <Formik
        initialValues={{
          email: "",
        }}
        initialStatus={{ success: false }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Invalid email")
            .max(255)
            .required("Email is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus }) => {
          const response = await authService.sendOtp(values.email);

          if (response.success) {
            toast.success("Reset password email successfully sent!");

            setStatus(response);
          } else {
            toast.error("Uh oh, something went wrong!");

            setStatus({ success: false });
            setErrors({ submit: response.message });
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          status,
        }) => (
          <Form onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert variant="danger" className="flex-fill alert-blink">
                <div className="alert-icon">
                  <AlertOctagon
                    className="position-relative top-50 start-50 translate-middle"
                    size={20}
                  />
                </div>
                <div className="alert-message font-weight-bold">
                  {errors.submit}
                </div>
              </Alert>
            )}
            {status.success && (
              <Alert variant="success" className="flex-fill alert-blink">
                <div className="alert-icon">
                  <Smile
                    className="position-relative top-50 start-50 translate-middle"
                    size={20}
                  />
                </div>
                <div className="alert-message font-weight-bold">
                  {status.message}
                </div>
              </Alert>
            )}
            <Form.Label>
              Email<span className="text-danger ms-1">*</span>
            </Form.Label>
            <InputGroup className="mb-3" hasValidation>
              <Form.Control
                size="lg"
                name="email"
                placeholder="Enter your email"
                value={values.email}
                isInvalid={Boolean(touched.email && errors.email)}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting || status.success}
              />
              <Button
                type="submit"
                variant="primary"
                size="lg"
                disabled={isSubmitting || status.success}
              >
                Send
              </Button>
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </InputGroup>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default SendOtpForm;
